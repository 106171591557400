import React, { useState, useContext, useEffect } from 'react';
import { Button, Form, Row, Col, Image } from 'react-bootstrap';

import { navigate } from 'hookrouter';

import AuthContext from '../../../context/Auth/auth';

import './index.css';

function LoginPage() {
  const context = useContext(AuthContext);

  const [emailState, setEmailState] = useState('');
  const [passwordState, setPasswordState] = useState('');
  const [statusState, setStatusState] = useState({
    color: 'black',
    status: '',
  });

  useEffect(() => {
    if (context.token) {
      navigate('/dispatch'); // TODO change to Overview
    }

    if (context.error) {
      setStatusState({
        color: 'red',
        status: context.error,
      });
    }
  }, [context.token, context.error]);

  function validateForm() {
    return emailState.length > 0 && passwordState.length > 0;
  }

  function handleSubmit(e) {
    e.preventDefault();

    setStatusState({
      color: 'blue',
      status: 'Validating',
    });

    context.login(emailState, passwordState);
  }

  return (
    <div className='Login'>
      <div className='login-header'></div>
      <form onSubmit={handleSubmit}>
        <Form.Group controlId='email'>
          <Row>
            <Col xs='3' sm='4'>
              <Form.Label>Email</Form.Label>
            </Col>
            <Col xs='9' sm='8'>
              <Form.Control
                autoFocus
                type='email'
                value={emailState}
                onChange={e => setEmailState(e.target.value)}
              />
            </Col>
          </Row>
        </Form.Group>
        <Form.Group controlId='password'>
          <Row>
            <Col xs='3' sm='4'>
              <Form.Label>Password</Form.Label>
            </Col>
            <Col xs='9' sm='8'>
              <Form.Control
                value={passwordState}
                onChange={e => setPasswordState(e.target.value)}
                type='password'
              />
            </Col>
          </Row>
        </Form.Group>
        <p style={{ color: statusState.color, textAlign: 'center' }}>
          {statusState.status}
        </p>
        <Button block size='large' disabled={!validateForm()} type='submit'>
          Login
        </Button>
      </form>
    </div>
  );
}

export default LoginPage;
