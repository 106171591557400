class Location {
  ZoneId: number | null;
  ShortName: string;
  LongName: string;
  Description: string;
  Latitude: number;
  Longitude: number;
  Radius: number;
  Color: string;
  IsActive: boolean;

  constructor(
    zoneId: number | null,
    shortName: string,
    longName: string,
    description: string,
    latitude: number,
    longitude: number,
    radius: number,
    color: string,
    isActive: boolean
  ) {
    this.ZoneId = zoneId;
    this.ShortName = shortName;
    this.LongName = longName;
    this.Description = description;
    this.Latitude = latitude;
    this.Longitude = longitude;
    this.Radius = radius;
    this.Color = color;
    this.IsActive = isActive;
  }
}

export default Location;
