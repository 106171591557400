import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import GoogleMapReact from 'google-map-react';

import AuthContext from '../../../../../context/Auth/auth';
import { getGoogleMapsApiKey } from '../../../../../config';
import { isEmpty } from '../../../../../helpers/ObjectHelper';
import {
  createExistingZoneMarkers,
  createExistingPointMarkers,
} from '../../../../../helpers/MapHelper';

// const options = {
//   panControl: false,
//   mapTypeControl: false,
//   scrollwheel: false,
//   styles: [
//     {
//       stylers: [
//         { saturation: -100 },
//         { gamma: 0.8 },
//         { lightness: 4 },
//         { visibility: 'on' },
//       ],
//     },
//   ],
// };
const options = {
  mapTypeId: 'satellite',
};

function CMSMap({ mapCenter, locations, selectedLocation, parkBoundary }) {
  const context = useContext(AuthContext);

  const [activeMap, setActiveMap] = useState(null);
  const [selectedZone, setSelectedZone] = useState({});
  const [wayPointMarkers, setWayPointMarkers] = useState([]);
  const [wayPointCircles, setWayPointCircles] = useState([]);
  const [centerPoint, setCenterPoint] = useState({});
  const [zoomLevel, setZoomLevel] = useState(18);

  const handleZoneClick = () => {
    console.log('clicked');
  };

  const handleApiLoaded = (map, maps) => {
    map.setTilt(0);

    const [markers] = createExistingZoneMarkers(map, locations);

    // Zones
    markers.forEach(marker => {
      marker.addListener('click', handleZoneClick);
      marker.setMap(map);
    });

    const parkPath = new maps.Polyline({
      path: context.tbProps.parkBoundary,
      geodesic: true,
      strokeColor: '#FFFFFF',
      strokeOpacity: 1.0,
      strokeWeight: 2,
    });
    parkPath.setMap(map);

    setActiveMap(map);
  };

  const clearMarkersFromMap = () => {
    wayPointMarkers.forEach(marker => {
      marker.setVisible(false);
      marker.setMap(null);
    });

    wayPointCircles.forEach(circle => {
      circle.setVisible(false);
      circle.setMap(null);
    });
  };

  useEffect(() => {
    if (selectedLocation !== null) {
      setSelectedZone(selectedLocation);
      setCenterPoint({
        lat: selectedLocation.Latitude,
        lng: selectedLocation.Longitude,
      });
      setZoomLevel(19);
    } else {
      setSelectedZone({});
      clearMarkersFromMap();
      setCenterPoint(mapCenter);
      setZoomLevel(18);
    }
  }, [selectedLocation, mapCenter]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isEmpty(selectedZone)) {
      clearMarkersFromMap();

      const [markers, circles] = createExistingPointMarkers(
        activeMap,
        selectedZone.WayPoints
      );

      setWayPointMarkers(markers);
      setWayPointCircles(circles);
    }
  }, [selectedZone]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (activeMap !== null) {
      activeMap.panTo(centerPoint);
      activeMap.setZoom(zoomLevel);
    }
  }, [centerPoint]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div style={{ height: '100%', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: getGoogleMapsApiKey() }}
        options={options}
        defaultCenter={mapCenter}
        center={centerPoint}
        defaultZoom={18}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
      ></GoogleMapReact>
    </div>
  );
}

CMSMap.propTypes = {
  mapCenter: PropTypes.object,
  locations: PropTypes.array,
  selectedLocation: PropTypes.object,
  parkBoundary: PropTypes.array,
};

export default CMSMap;
