import { withStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

const DangerButton = withStyles(() => ({
  root: {
    color: '#f8f8f8',
    backgroundColor: '#D8274A',
    '&:hover': {
      backgroundColor: '#D8274A',
    },
    '&:disabled': {
      backgroundColor: '#bdbdbd',
      color: '#424242',
    },
  },
}))(Button);

export default DangerButton;
