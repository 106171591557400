import { useEffect, useState, useContext } from 'react';
import { navigate } from 'hookrouter';
import { Doughnut } from 'react-chartjs-2';

import AuthContext from 'context/Auth/auth';
import { UserContext } from 'context/UserContext';
import { checkAuthorization } from 'helpers/HasAuthorization';
import { IUser } from 'models/IUser';
import {
  getUsers,
  getUserByToken,
  updateUserRoles,
  setUserParks,
} from 'services/Users.Service';
import { IPark } from 'models/IPark';
import { fetchParksForOrg } from 'services/Parks.Service';

import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  CircularProgress,
  Checkbox,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Avatar,
  IconButton,
  FormControlLabel,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    maxWidth: 752,
  },
  demo: {
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    margin: theme.spacing(4, 0, 2),
  },
}));

const AllRoles = [
  { value: 1, name: 'TraKidAdmin' },
  { value: 2, name: 'TraKidCs' },
  { value: 3, name: 'OrgAdmin' },
  { value: 4, name: 'ParkAdmin' },
  { value: 5, name: 'SecurityAdmin' },
  { value: 6, name: 'StaffApp' },
  { value: 7, name: 'Dispatch' },
  { value: 8, name: 'TrackerView' },
  { value: 9, name: 'DevTools' },
  { value: 10, name: 'Devices' },
  { value: 11, name: 'Reports' },
  { value: 12, name: 'EditScavenger' },
  { value: 13, name: 'ToggleScavenger' },
  { value: 14, name: 'ViewScavenger' },
  { value: 15, name: 'Parent' },
  { value: 16, name: 'InitialAccount' },
  { value: 17, name: 'Guardian' },
];

const data = {
  labels: [
    'Org Admin',
    'Park Admin',
    'Security',
    'Explorer Quest (Edit)',
    'Explorer Quest (View)',
    'Explorer Quest (Toggle)',
    'Reports',
    'Devices',
  ],
  datasets: [
    {
      label: '# of Users',
      data: [1, 2, 3, 4, 5, 6, 7, 8],
      backgroundColor: [
        'rgba(26, 188, 156, 0.2)',
        'rgba(46, 204, 113, 0.2)',
        'rgba(52, 152, 219, 0.2)',
        'rgba(155, 89, 182, 0.2)',
        'rgba(52, 73, 94, 0.2)',
        'rgba(241, 196, 15, 0.2)',
        'rgba(230, 126, 34, 0.2)',
        'rgba(231, 76, 60, 0.2)',
      ],
      borderColor: [
        'rgba(26, 188, 156, 1.0)',
        'rgba(46, 204, 113, 1.0)',
        'rgba(52, 152, 219, 1.0)',
        'rgba(155, 89, 182, 1.0)',
        'rgba(52, 73, 94, 1.0)',
        'rgba(241, 196, 15, 1.0)',
        'rgba(230, 126, 34, 1.0)',
        'rgba(231, 76, 60, 1.0)',
      ],
      borderWidth: 1,
    },
  ],
};

function UserManagement() {
  const authContext = useContext(AuthContext);
  const { state, dispatch } = useContext(UserContext);

  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [open, setOpen] = useState(false);

  const [userRowData, setUserRowData] = useState([]);
  const [selectedUser, setSelectedUser] = useState<IUser>();
  const [allParksInOrg, setAllParksInOrg] = useState<IPark[]>();
  const [roleDidChange, setRoleDidChange] = useState(false);
  const [userParkDidChange, setUserParkDidChange] = useState(false);

  interface IChangeValues {
    value: string;
    checked: boolean;
  }

  const handleClickOpen = (user: IUser): void => {
    console.log(user);
    setSelectedUser(user);
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
    setSelectedUser(undefined);
  };

  const handleRoleChange = ({ value, checked }: IChangeValues): void => {
    setRoleDidChange(true);

    let roles = [...selectedUser!.UserRoles];

    if (checked) {
      roles.push(parseInt(value));
    } else {
      roles = roles.filter(role => role !== parseInt(value));
    }

    setSelectedUser({
      ...selectedUser!,
      UserRoles: roles,
    });
  };

  const handleParkChange = ({ value, checked }: IChangeValues): void => {
    setUserParkDidChange(true);

    let parks = [...selectedUser!.Parks];

    // selectedUser!.Parks.forEach(park => parks.push(park.Id));

    if (checked) {
      parks.push(parseInt(value));
    } else {
      parks = parks.filter(id => id !== parseInt(value));
    }

    console.log('parks on user', parks);
    setSelectedUser({
      ...selectedUser!,
      Parks: parks,
    });
  };

  const handleUpdateUser = (): void => {
    setUpdating(true);

    if (roleDidChange) {
      updateUserRoles(authContext, selectedUser!.Id, selectedUser!.UserRoles)
        .then(response => {
          //TODO alert user
          console.log(response);
        })
        .catch(error => {
          //TODO alert user
          console.error(error);
        })
        .finally(() => {
          getUsers(authContext, state.orgId).then(response => {
            const users = response.data;

            setUserRowData(users);
            setUpdating(false);
            handleClose();
          });
        });
    }

    if (userParkDidChange) {
      setUserParks(
        authContext,
        state.orgId,
        selectedUser!.Id,
        selectedUser!.Parks,
        state.userId
      )
        .then(response => {
          console.log(response);
        })
        .catch(error => {
          console.error(error);
        })
        .finally(() => {
          getUsers(authContext, state.orgId).then(response => {
            const users = response.data;

            setUserRowData(users);
            setUpdating(false);
            handleClose();
          });
        });
    }
  };

  useEffect(() => {
    setLoading(true);

    if (state.orgId !== null) {
      // Check for Authorization
      if (state.userRoles.indexOf(1) === -1) {
        navigate('/not-authorized');
      }
    }

    if (state === undefined) {
      getUserByToken(authContext.token).then(user => {
        const { UserRoles, EditableRoles, OrgId, Parks, Id } = JSON.parse(
          user.data
        );

        const parks = Parks.map((park: IPark) => park.Id);

        dispatch({ type: 'updateUserRoles', payload: UserRoles });
        dispatch({ type: 'updateEditRoles', payload: EditableRoles });
        dispatch({ type: 'updateUserOrgId', payload: OrgId });
        dispatch({ type: 'updateUserParkAccess', payload: parks });
        dispatch({ type: 'updateUserId', payload: Id });

        getUsers(authContext, OrgId).then(response => {
          const users = response.data;

          users.forEach((user: IUser) => {
            user.Parks = user.Parks.map((park: any) => park.Id);
          });

          setUserRowData(users);
          setLoading(false);
        });

        fetchParksForOrg(authContext, OrgId).then(response => {
          setAllParksInOrg(response);
        });
      });
    } else {
      getUsers(authContext, state.orgId).then(response => {
        const users = response.data;

        users.forEach((user: IUser) => {
          user.Parks = user.Parks.map((park: any) => park.Id);
        });

        setUserRowData(users);
        setLoading(false);
      });

      fetchParksForOrg(authContext, state.orgId).then(response => {
        setAllParksInOrg(response);
      });
    }
  }, [state]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {loading ? (
        <CircularProgress style={{ alignSelf: 'center' }} />
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography variant='h6' className={classes.title}>
              Users
            </Typography>
            <div className={classes.demo}>
              <List>
                {userRowData.map((user: IUser) => {
                  return (
                    <ListItem key={user.Id}>
                      <ListItemAvatar>
                        <Avatar>
                          <AccountCircleIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={user.UserName}
                        secondary={`${user.LastName}, ${user.FirstName}`}
                      />
                      <ListItemSecondaryAction>
                        <IconButton
                          edge='end'
                          aria-label='edit user roles'
                          onClick={() => handleClickOpen(user)}
                        >
                          <EditIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  );
                })}
              </List>
            </div>
          </Grid>
          {/* <Grid item xs={12} md={6}>
            <Doughnut type='doughnut' data={data} />
          </Grid> */}
        </Grid>
      )}

      {/* Must have a selected user */}
      {open ? (
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby='form-dialog-title'
        >
          <DialogTitle id='form-dialog-title'>
            Available Roles and Parks
          </DialogTitle>
          <DialogContent>
            {/* TODO Text copy needed */}
            <DialogContentText>
              Adjust the authorization level of users and and assign access to
              parks withing the organization.
            </DialogContentText>

            {AllRoles.map(role => {
              return (
                <FormControlLabel
                  key={role.value}
                  control={
                    <Checkbox
                      checked={selectedUser!.UserRoles.indexOf(role.value) > -1}
                      onChange={e => handleRoleChange(e.target)}
                      name={role.name}
                      value={role.value}
                      color='primary'
                      disabled={
                        state.editableRoles.indexOf(role.value) > -1
                          ? false
                          : true
                      }
                    />
                  }
                  label={role.name}
                />
              );
            })}

            <hr />

            {allParksInOrg?.map(park => {
              return (
                <FormControlLabel
                  key={park.ParkId}
                  control={
                    <Checkbox
                      checked={
                        selectedUser!.Parks.find(p => p === park.ParkId) !==
                        undefined
                      }
                      onChange={e => handleParkChange(e.target)}
                      name={park.Name}
                      value={park.ParkId}
                      color='primary'
                    />
                  }
                  label={park.Name}
                />
              );
            })}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color='primary' disabled={updating}>
              Cancel
            </Button>
            <Button
              onClick={handleUpdateUser}
              variant='outlined'
              color='primary'
              disabled={updating}
            >
              Update
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
    </>
  );
}

export default UserManagement;
