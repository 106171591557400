import { ChangeEvent } from 'react';

import { Tutorial } from 'models/Tutorial';

import { Grid, TextField } from '@material-ui/core';

interface Props {
  tutorial: Tutorial;
  onFormFieldUpdate: (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
}
const WelcomeScreenTwoForm = ({ tutorial, onFormFieldUpdate }: Props) => (
  <form>
    <Grid
      container
      spacing={2}
      direction='column'
      justify='center'
      alignItems='stretch'
    >
      <Grid item>
        <TextField
          id='welcome-text-1'
          label='Welcome Text 1'
          onChange={onFormFieldUpdate}
          name='WelcomeText1'
          value={tutorial.WelcomeText1}
          fullWidth
          variant='outlined'
        />
      </Grid>

      <Grid item>
        <TextField
          id='welcome-text-2'
          label='Welcome Text 2'
          onChange={onFormFieldUpdate}
          name='WelcomeText2'
          value={tutorial.WelcomeText2}
          fullWidth
          variant='outlined'
        />
      </Grid>
      <Grid item>
        <TextField
          id='welcome-text-3'
          label='Welcome Text 3'
          onChange={onFormFieldUpdate}
          name='WelcomeText3'
          value={tutorial.WelcomeText3}
          fullWidth
          variant='outlined'
        />
      </Grid>
    </Grid>
  </form>
);

export default WelcomeScreenTwoForm;
