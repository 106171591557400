import axios from 'axios';
import { getBaseUrl, getAPIVersion } from '../config';

const version = getAPIVersion(true);
const path = `/api/${version}/trackermasters/`;

axios.defaults.headers.post['Content-Type'] = 'application/json';

export function getTrackerMasterProperties(context, callback) {
  fetch(getBaseUrl() + path + 'gettrackermasterproperties', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + context.token,
    },
    body: JSON.stringify({}),
  })
    .then(res => res.json())
    .then(data => {
      callback(data);
    })
    .catch(console.log);
}

export function getBatches(context) {
  return axios({
    method: 'post',
    url: getBaseUrl() + path + 'getbatches',
    headers: { Authorization: `Bearer ${context.token}` },
    data: {},
  });
}

export function getBatch(context, batchId) {
  return axios({
    method: 'post',
    url: getBaseUrl() + path + 'getbatch',
    headers: { Authorization: `Bearer ${context.token}` },
    data: {
      batchId,
    },
  });
}

export function updateTrackerMaster(context, tracker) {
  return axios({
    method: 'post',
    url: getBaseUrl() + path + 'updatetrackermaster',
    headers: { Authorization: `Bearer ${context.token}` },
    data: {
      ...tracker,
    },
  });
}
