import { useEffect, useState } from 'react';

import { Tutorial } from 'models/Tutorial';

import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress, Grid } from '@material-ui/core';

import defaultImg from '../../../../../images/cms/welcome/park-logo-placeholder.png';
import parkSplash from '../../../../../images/cms/welcome/park-splash-screen.png';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flexGrow: 1,
    background: `url(${parkSplash}) no-repeat center`,
    backgroundSize: 'contain',
    height: '700px',

    '& > *': {
      boxSizing: 'content-box',
      // border: '1px solid red',
      width: '320px',
    },
  },
  loadingImage: {
    display: 'grid',
    maxWidth: '320px',
    height: '150px',
    marginTop: '80px',
    justifyItems: 'center',
    alignItems: 'center',
  },
  parkImage: {
    display: 'grid',
    maxWidth: '320px',
    marginTop: '80px',
  },
  image: {
    justifySelf: 'center',
    height: '210px',
    width: '210px',
    borderRadius: '10px',
  },
  welcomeTextTop: {
    color: '#FFFFFF',
    textAlign: 'center',
    marginTop: '40px',
    marginBottom: 0,
    maxWidth: '320px',
    overflow: 'hidden',

    '& > p': {
      fontSize: '1.25rem',
    },
  },
  welcomeText: {
    color: '#FFFFFF',
    textAlign: 'center',
    marginTop: '2px',
    marginBottom: 0,
    maxWidth: '320px',
    overflow: 'hidden',

    '& > p': {
      fontSize: '1.25rem',
    },
  },
}));

interface Props {
  tutorial: Tutorial;
  parkImage: string | null;
  loading: boolean;
}
function WelcomeScreenTwoPreview({ tutorial, parkImage, loading }: Props) {
  const classes = useStyles();

  const [image, setImage] = useState(defaultImg);

  useEffect(() => {
    if (parkImage !== null) {
      setImage(parkImage);
    }
  }, [parkImage]);

  return (
    <>
      <Grid container className={classes.root}>
        {loading ? (
          <Grid item className={classes.loadingImage}>
            <CircularProgress color='secondary' />
          </Grid>
        ) : (
          <Grid item className={classes.parkImage}>
            <img src={image} alt='Point Imagery' className={classes.image} />
          </Grid>
        )}

        <Grid item className={classes.welcomeTextTop}>
          {tutorial.WelcomeText1 !== '' ? (
            <p>{tutorial.WelcomeText1}</p>
          ) : (
            <p>
              <em>Welcome Text 1 Placeholder</em>
            </p>
          )}
        </Grid>

        <Grid item className={classes.welcomeText}>
          {tutorial.WelcomeText2 !== '' ? (
            <p>{tutorial.WelcomeText2}</p>
          ) : (
            <p>
              <em>Welcome Text 2 Placeholder</em>
            </p>
          )}
        </Grid>

        <Grid item className={classes.welcomeText}>
          {tutorial.WelcomeText3 !== '' ? (
            <p>{tutorial.WelcomeText3}</p>
          ) : (
            <p>
              <em>Welcome Text 3 Placeholder</em>
            </p>
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default WelcomeScreenTwoPreview;
