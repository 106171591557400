import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'hookrouter';
import contrast from 'font-color-contrast';

import AuthContext from 'context/Auth/auth';
import { updatePointStatus } from 'services/Waypoint.Service';

import { withStyles, makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Button,
  ButtonGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Avatar,
  FormControlLabel,
  Switch,
  CircularProgress,
} from '@material-ui/core';

//#region Styles
const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles(theme => ({
  table: {},
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));
//#endregion

function WaypointList({ zone, points, confirmDeletePoint }) {
  const authContext = useContext(AuthContext);
  const classes = useStyles();

  const [waypoints, setWaypoints] = useState(points);
  const [updatingStatus, setUpdatingStatus] = useState(false);

  const handleStatusToggle = (e, point) => {
    setUpdatingStatus(true);

    let waypointsCopy = waypoints;
    const updatedPoint = waypointsCopy.find(p => p.Id === point.Id);

    if (point.IsActive === undefined) {
      point.IsActive = false;
    }

    point.IsActive = !point.IsActive;
    updatedPoint.IsActive = point.IsActive;

    updatePointStatus(
      authContext,
      authContext.tbProps.selectedPark.ParkId,
      point.ZoneId,
      point.Id,
      point.IsActive
    ).then(_msg => {
      console.log(_msg);

      const indexForPoint = waypointsCopy.indexOf(updatedPoint);
      waypointsCopy.splice(indexForPoint, 1, updatedPoint);

      setWaypoints(waypointsCopy);
      setUpdatingStatus(false);
    });
  };

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label='customized table'>
        <TableHead>
          <TableRow>
            <StyledTableCell
              style={{
                backgroundColor: zone.Color,
                color: contrast(zone.Color),
              }}
            >
              Name
            </StyledTableCell>
            <StyledTableCell
              style={{
                backgroundColor: zone.Color,
                color: contrast(zone.Color),
              }}
            >
              Description
            </StyledTableCell>
            <StyledTableCell
              style={{
                backgroundColor: zone.Color,
                color: contrast(zone.Color),
              }}
            >
              Color
            </StyledTableCell>
            <StyledTableCell
              style={{
                backgroundColor: zone.Color,
                color: contrast(zone.Color),
              }}
            >
              Currently Active
            </StyledTableCell>
            <StyledTableCell
              style={{
                backgroundColor: zone.Color,
                color: contrast(zone.Color),
              }}
            >
              Actions
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {waypoints.map(point => (
            <StyledTableRow key={point.Id}>
              <StyledTableCell component='th' scope='row'>
                {point.ShortName}
              </StyledTableCell>
              <StyledTableCell>{point.Description}</StyledTableCell>
              <StyledTableCell>
                <Avatar style={{ backgroundColor: point.Color }}>&nbsp;</Avatar>
              </StyledTableCell>
              <StyledTableCell>
                {updatingStatus ? (
                  <CircularProgress />
                ) : (
                  <FormControlLabel
                    onClick={event => event.stopPropagation()}
                    onFocus={event => event.stopPropagation()}
                    control={
                      <Switch
                        color='primary'
                        checked={point.IsActive}
                        value={point.IsActive}
                        onChange={e => handleStatusToggle(e, point)}
                      />
                    }
                    label={
                      <Typography className={classes.secondaryHeading}>
                        Active
                      </Typography>
                    }
                    labelPlacement='start'
                  />
                )}
                {/* <FormControlLabel
                  control={
                    <Switch
                      color='primary'
                      checked={point.IsActive}
                      value={point.IsActive}
                      onChange={e => handleStatusToggle(e, point)}
                    />
                  }
                  label={
                    <Typography className={classes.secondaryHeading}>
                      Active
                    </Typography>
                  }
                  labelPlacement='top'
                /> */}
              </StyledTableCell>

              <StyledTableCell>
                <ButtonGroup color='primary' aria-label='waypoint-edit-options'>
                  <Button
                    variant='contained'
                    onClick={() =>
                      navigate(
                        `/explorer-quest/zone/${zone.ZoneId}/waypoint/${point.Id}/edit`
                      )
                    }
                  >
                    Edit
                  </Button>
                  <Button
                    variant='contained'
                    onClick={() =>
                      confirmDeletePoint(zone.ZoneId, zone.ParkId, point.Id)
                    }
                    style={{
                      backgroundColor: '#e74c3c',
                      color: '#ecf0f1',
                    }}
                  >
                    Delete
                  </Button>
                </ButtonGroup>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>

      <Button
        variant='contained'
        color='primary'
        disabled={false}
        style={{ margin: '10px' }}
        onClick={() =>
          navigate(`/explorer-quest/zone/${zone.ZoneId}/waypoint/new`)
        }
      >
        Create a New Point
      </Button>
    </TableContainer>
  );
}

WaypointList.propTypes = {
  zone: PropTypes.object,
  points: PropTypes.array,
  confirmDeletePoint: PropTypes.func.isRequired,
};

export default WaypointList;
