import uuid from 'react-uuid';
import uniqolor from 'uniqolor';

import { getBaseUrl, getAPIVersion } from '../config';
import { Zone } from '../models/Zone';

const version = getAPIVersion();
const path = `/api/${version}/Zones/`;

//#region HELPERS
export function fetchNewZone() {
  const zoneId = uuid();
  const shortName = '';
  const longName = '';
  const description = '';
  const latitude = 0;
  const longitude = 0;
  const radius = 20;
  const color = uniqolor.random();
  const parkId = uuid();
  const isActive = true;

  return new Zone(
    zoneId,
    shortName,
    longName,
    description,
    latitude,
    longitude,
    radius,
    color.color,
    parkId,
    isActive
  );
}
//#endregion

//#region API CALLS
export async function getZones(context, parkId) {
  const response = await fetch(getBaseUrl() + path + 'getzones', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + context.token,
    },
    body: JSON.stringify({
      parkId: parkId,
    }),
  });

  if (response.ok) {
    try {
      return response.json();
    } catch (error) {
      console.error(error);
    }
  }
}

export async function getZone(context, ParkId, ZoneId) {
  const response = await fetch(getBaseUrl() + path + 'getzone', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + context.token,
    },
    body: JSON.stringify({
      parkId: ParkId,
      zoneId: ZoneId,
    }),
  });

  if (response.ok) {
    try {
      return response.json();
    } catch (error) {
      console.error(error);
    }
  }
}

export async function addZone(context, data, parkId) {
  const response = await fetch(getBaseUrl() + path + 'addzone', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + context.token,
    },
    body: JSON.stringify({
      parkId: parkId,
      shortName: data.ShortName,
      longName: data.LongName,
      description: data.Description,
      latitude: data.Latitude,
      longitude: data.Longitude,
      radius: data.Radius,
      color: data.Color,
    }),
  });

  if (response.ok) {
    try {
      return response.json();
    } catch (error) {
      console.error(error);
    }
  }
}

export async function setZoneStatus(context, parkId, zoneId, isActive) {
  const response = await fetch(getBaseUrl() + path + 'setzoneactive', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + context.token,
    },
    body: JSON.stringify({
      parkId,
      zoneId,
      isActive,
    }),
  });

  if (response.ok) {
    try {
      return response.json();
    } catch (error) {
      console.error(error);
    }
  }
}

export async function updateZone(context, data, ParkId, ZoneId) {
  const response = await fetch(getBaseUrl() + path + 'updatezone', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + context.token,
    },
    body: JSON.stringify({
      parkId: ParkId,
      zoneId: ZoneId,
      shortName: data.ShortName,
      longName: data.LongName,
      description: data.Description,
      latitude: data.Latitude,
      longitude: data.Longitude,
      radius: data.Radius,
      color: data.Color,
    }),
  });

  if (response.ok) {
    try {
      return response.json();
    } catch (error) {
      console.error(error);
    }
  }
}

export async function deleteZone(context, ParkId, ZoneId) {
  const response = await fetch(getBaseUrl() + path + 'deletezone', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + context.token,
    },
    body: JSON.stringify({
      parkId: ParkId,
      zoneId: ZoneId,
    }),
  });

  if (response.ok) {
    try {
      return response.json();
    } catch (error) {
      console.error(error);
    }
  }
}

export async function updateZoneStatus(context, parkId, zoneId, isActive) {
  const response = await fetch(getBaseUrl() + path + 'setzoneactive', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + context.token,
    },
    body: JSON.stringify({
      parkId,
      zoneId,
      isActive,
    }),
  });

  if (response.ok) {
    try {
      return response.json();
    } catch (error) {
      console.error(error);
    }
  }
}
//#endregion
