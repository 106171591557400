import { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';

import AuthContext from 'context/Auth/auth';
import DangerButton from 'components/common/buttons/DangerButton';
import { ToolCardForm } from '../ToolCardForm';

import {
  clearTrackerData,
  clearAccountData,
  clearScavengerRecords,
} from 'services/Tools.Service';

import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Card,
  CardActions,
  CardContent,
  Input,
  InputLabel,
  FormControl,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexGrow: 1,
  },
  toolCard: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    padding: '10px',
  },
}));

function DataTools({ triggerSnack, setMessage, setSeverity }) {
  const context = useContext(AuthContext);
  const classes = useStyles();

  // CLEAR ACCOUNT DATA
  const [accountDataInput, setAccountDataInput] = useState('');
  const [disableClearAccountBtn, setDisableClearAccountBtn] = useState(true);

  // CLEAR TRACKER DATA
  const [trackerDataInput, setTrackerDataInput] = useState('');
  const [disableClearTrackerBtn, setDisableClearTrackerBtn] = useState(true);

  // CLEAR RECORD DATA
  const [recordDataInput, setRecordDataInput] = useState('');
  const [disableClearRecordBtn, setDisableClearRecordBtn] = useState(true);

  //#region Tool Functions

  const clearTracker = () => {
    clearTrackerData(trackerDataInput).then(_msg => {
      if (!_msg.InternalCode) {
        setMessage(_msg.Message);
        setSeverity('success');
        triggerSnack(true);
      } else {
        setMessage(_msg.Message);
        setSeverity('error');
        triggerSnack(true);
      }
      setTrackerDataInput('');
    });
  };

  const clearAccount = () => {
    clearAccountData(accountDataInput, context.token, data => {
      alert(data.Message);
      setAccountDataInput('');
      setDisableClearAccountBtn(true);
    });
  };

  const clearRecordData = () => {
    clearScavengerRecords(context, recordDataInput).then(_msg => {
      if (!_msg.InternalCode) {
        setMessage(_msg.Message);
        setSeverity('success');
        triggerSnack(true);
      } else {
        setMessage(_msg.Message);
        setSeverity('error');
        triggerSnack(true);
      }

      setRecordDataInput('');
      setDisableClearRecordBtn(true);
    });
  };
  //#endregion

  //#region Input Handlers
  const handleTrackerDataInputChange = trackerId => {
    setTrackerDataInput(trackerId);
    setDisableClearTrackerBtn(false);
  };

  const handleAccountDataInputChange = value => {
    setAccountDataInput(value);
  };

  const handleRecordDataInputChange = value => {
    setRecordDataInput(value);
  };
  //#endregion

  //#region Effects
  useEffect(() => {
    trackerDataInput === ''
      ? setDisableClearTrackerBtn(true)
      : setDisableClearTrackerBtn(false);
  }, [trackerDataInput]);

  useEffect(() => {
    accountDataInput === ''
      ? setDisableClearAccountBtn(true)
      : setDisableClearAccountBtn(false);
  }, [accountDataInput]);

  useEffect(() => {
    recordDataInput === ''
      ? setDisableClearRecordBtn(true)
      : setDisableClearRecordBtn(false);
  }, [recordDataInput]);
  //#endregion

  return (
    <>
      <Grid container spacing={2}>
        {/* CLEAR TRACKER DATA */}
        <Grid item xs={12} md={6} lg={3}>
          <Card className={classes.toolCard}>
            <CardContent>
              <ToolCardForm actionLabel='Clear Tracker Data'>
                <FormControl>
                  <InputLabel htmlFor='trackerDataInput'>Tracker ID</InputLabel>
                  <Input
                    id='trackerDataInput'
                    value={trackerDataInput}
                    onChange={e => handleTrackerDataInputChange(e.target.value)}
                  />
                </FormControl>
              </ToolCardForm>
            </CardContent>
            <CardActions>
              <DangerButton
                onClick={clearTracker}
                disabled={disableClearTrackerBtn}
              >
                Clear
              </DangerButton>
            </CardActions>
          </Card>
        </Grid>

        {/* CLEAR ACCOUNT DATA */}
        <Grid item xs={12} md={6} lg={3}>
          <Card className={classes.toolCard}>
            <CardContent>
              <ToolCardForm actionLabel='Clear Account Data'>
                <FormControl>
                  <InputLabel htmlFor='phoneNumberInput'>
                    Account Number
                  </InputLabel>
                  <Input
                    id='phoneNumberInput'
                    value={accountDataInput}
                    onChange={e => handleAccountDataInputChange(e.target.value)}
                  />
                </FormControl>
              </ToolCardForm>
            </CardContent>
            <CardActions>
              <DangerButton
                onClick={clearAccount}
                disabled={disableClearAccountBtn}
              >
                Clear
              </DangerButton>
            </CardActions>
          </Card>
        </Grid>

        {/* CLEAR RECORD DATA */}
        <Grid item xs={12} md={6} lg={3}>
          <Card className={classes.toolCard}>
            <CardContent>
              <ToolCardForm actionLabel='Clear Record Data'>
                <FormControl>
                  <InputLabel htmlFor='phoneNumberInput2'>
                    Account Number
                  </InputLabel>
                  <Input
                    id='phoneNumberInput2'
                    value={recordDataInput}
                    onChange={e => handleRecordDataInputChange(e.target.value)}
                  />
                </FormControl>
              </ToolCardForm>
            </CardContent>
            <CardActions>
              <DangerButton
                onClick={clearRecordData}
                disabled={disableClearRecordBtn}
              >
                Clear
              </DangerButton>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

DataTools.propTypes = {
  triggerSnack: PropTypes.func.isRequired,
  setMessage: PropTypes.func.isRequired,
  setSeverity: PropTypes.func.isRequired,
};

export default DataTools;
