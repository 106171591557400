import { Tutorial } from 'models/Tutorial';

import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

import navigateScreenBg from '../../../../../images/cms/blank-screens/how-to-play-2.png';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flexGrow: 1,
    background: `url(${navigateScreenBg}) no-repeat center`,
    backgroundSize: 'contain',
    // height: '700px',
    height: '654px',

    '& > *': {
      boxSizing: 'content-box',
      // border: '1px solid red',
      // width: '320px',
    },
  },
  headerText: {
    color: '#FFFFFF',
    textAlign: 'center',
    marginTop: '30px',
    marginBottom: 0,

    '& > h3': {
      fontSize: '1.4rem',
    },
  },
  description: {
    color: '#000000',
    textAlign: 'left',
    fontSize: '1.3rem',
    maxWidth: '300px',
    marginBottom: '10px',
    marginTop: '5px',
    width: '300px',

    '& > h3': {
      fontSize: '1.35rem',
      fontWeight: 'bolder',
    },

    '& > p': {
      fontSize: '16px',
    },
  },
}));

interface Props {
  tutorial: Tutorial;
}
function NavigatePreview({ tutorial }: Props) {
  const classes = useStyles();

  return (
    <>
      <Grid container className={classes.root}>
        <Grid item className={classes.headerText}>
          {tutorial.StateText1 !== '' ? (
            <h3>{tutorial.StateText1}</h3>
          ) : (
            <h3>Navigate</h3>
          )}
        </Grid>

        <Grid item className={classes.description}>
          {tutorial.Title1 !== '' ? (
            <h3>{tutorial.Title1}</h3>
          ) : (
            <h3>
              <em>Title Placeholder</em>
            </h3>
          )}

          {tutorial.Description1 !== '' ? (
            <p>{tutorial.Description1}</p>
          ) : (
            <p>
              <em>Description Placeholder</em>
            </p>
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default NavigatePreview;
