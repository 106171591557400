import { ChangeEvent } from 'react';

import { Tutorial } from 'models/Tutorial';

import { Grid, TextField } from '@material-ui/core';

interface Props {
  tutorial: Tutorial;
  onFormFieldUpdate: (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
}
const GameStatesForm = ({ tutorial, onFormFieldUpdate }: Props) => {
  const inputProps = {
    maxLength: 18,
  };

  return (
    <form>
      <Grid
        container
        spacing={2}
        direction='column'
        justify='center'
        alignItems='stretch'
      >
        <Grid item>
          <TextField
            id='game-state-1'
            label='Game State 1'
            onChange={onFormFieldUpdate}
            name='StateText1'
            value={tutorial.StateText1}
            fullWidth
            variant='outlined'
            inputProps={inputProps}
          />
        </Grid>

        <Grid item>
          <TextField
            id='game-state-2'
            label='Game State 2'
            onChange={onFormFieldUpdate}
            name='StateText2'
            value={tutorial.StateText2}
            fullWidth
            variant='outlined'
            inputProps={inputProps}
          />
        </Grid>
        <Grid item>
          <TextField
            id='game-state-3'
            label='Game State 3'
            onChange={onFormFieldUpdate}
            name='StateText3'
            value={tutorial.StateText3}
            fullWidth
            variant='outlined'
            inputProps={inputProps}
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default GameStatesForm;
