import { getBaseUrl, getAPIVersion, getSecretKey } from '../config';

const version = getAPIVersion();
const sk = getSecretKey();
const path = `/api/${version}/accounts/`;

export function addAccountEntry(accountId, latitude, longitude, context) {
  fetch(getBaseUrl() + path + 'addaccountentry', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + context.token,
    },
    body: JSON.stringify({
      accountId: accountId,
      latitude: latitude,
      longitude: longitude,
      appState: 'SPOOFED',
    }),
  })
    .then(res => res.json())
    .then(data => {})
    .catch(console.log);
}

export function getAccounts(context, callback) {
  fetch(getBaseUrl() + path + 'getaccounts', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + context.token,
    },
    body: JSON.stringify({
      count: 0,
      s: sk,
    }),
  })
    .then(res => res.json())
    .then(data => {
      callback(data);
    })
    .catch(console.log);
}

export function getAccountEntries(accountId, count, context, callback) {
  fetch(getBaseUrl() + path + 'getaccountentries', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + context.token,
    },
    body: JSON.stringify({
      count: count,
      accountId: accountId,
    }),
  })
    .then(res => res.json())
    .then(data => {
      callback(data);
    })
    .catch(console.log);
}
