import { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useCookies } from 'react-cookie';
import { A } from 'hookrouter';

import { UserContext } from 'context/UserContext';
import { hasAuthorization } from 'helpers/HasAuthorization';
import brand from 'images/TRAKID_White.png';
import SelectDropdown from 'components/SelectDropdown';
import { getOrg, getOrganizations } from 'services/OrgsService';
import Organization from 'models/Organization';
import { fetchParksForOrg } from 'services/Parks.Service';
import Park from 'models/Park';

import { getEnvironment } from 'config';

import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  MenuItem,
  FormControl,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from '@material-ui/core';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import MoreIcon from '@material-ui/icons/MoreVert';
import StorageIcon from '@material-ui/icons/Storage';

const useStyles = makeStyles(theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: 240,
    width: `calc(100% - 240px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

function PrimaryAppBar({ hidden, adminProps, shift }) {
  const classes = useStyles();
  const [cookies, ,] = useCookies(['token']);
  const { state, dispatch } = useContext(UserContext);

  const [organizations, setOrganizations] = useState([]);
  const [parks, setParks] = useState([]);
  const [selectedPark, setSelectedPark] = useState(null);

  const [parkSelectDisabled, setParkSelectDisabled] = useState(true);
  const [orgSelectDisabled, setOrgSelectDisabled] = useState(false);

  const [parkMenuAnchor, setParkMenuAnchor] = useState(null);
  const [accountMenuAnchor, setAccountMenuAnchor] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);

  const isParkMenuOpen = Boolean(parkMenuAnchor);
  const isAccountMenuOpen = Boolean(accountMenuAnchor);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleParkMenuOpen = event => {
    setParkMenuAnchor(event.currentTarget);
  };

  const handleProfileMenuOpen = event => {
    setAccountMenuAnchor(event.currentTarget);
  };

  const confirmMenuClose = () => {
    adminProps.setSelectedPark(selectedPark);
    handleMenuClose();
  };

  const handleMenuClose = () => {
    setAccountMenuAnchor(null);
    setParkMenuAnchor(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = event => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  //#region Park Picker
  const parkPicker = 'primary-park-picker-menu';
  const renderParkMenu = (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      open={isParkMenuOpen}
      onClose={handleMenuClose}
    >
      <DialogTitle>Select Park</DialogTitle>
      <DialogContent>
        <form className={classes.container}>
          <FormControl
            className={classes.formControl}
            disabled={orgSelectDisabled}
          >
            <SelectDropdown
              labelId='org-dialog-select-label'
              label='Organizations'
              items={organizations}
              onItemSelected={adminProps.setSelectedOrg}
              selected={adminProps.selectedOrg}
            />
          </FormControl>
          <FormControl
            className={classes.formControl}
            disabled={parkSelectDisabled}
          >
            <SelectDropdown
              labelId='park-dialog-select-label'
              label='Parks'
              items={parks}
              onItemSelected={setSelectedPark}
              selected={selectedPark}
            />
          </FormControl>
        </form>
        {orgSelectDisabled && parkSelectDisabled ? (
          <p>
            <em>Contact your team administrator to add you to a park.</em>
          </p>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleMenuClose} color='primary'>
          Cancel
        </Button>
        <Button onClick={confirmMenuClose} color='primary'>
          Set Park
        </Button>
      </DialogActions>
    </Dialog>
  );
  //#endregion

  //#region Account
  const menuId = 'primary-account-menu';
  const renderAccountMenu = (
    <Menu
      anchorEl={accountMenuAnchor}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isAccountMenuOpen}
      onClose={handleMenuClose}
    >
      <A href='/profile'>
        <MenuItem>Profile</MenuItem>
      </A>
      <A href='/logout'>
        <MenuItem onClick={handleMenuClose}>Logout</MenuItem>
      </A>
    </Menu>
  );
  //#endregion

  const mobileMenuId = 'primary-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={handleParkMenuOpen}>
        <IconButton
          aria-label='park selection'
          color='inherit'
          aria-controls={parkPicker}
          aria-haspopup='true'
        >
          <AccountTreeIcon />
        </IconButton>
        <span>Parks</span>
      </MenuItem>
      <MenuItem>
        <IconButton
          aria-label='account of current user'
          aria-controls='primary-search-account-menu'
          aria-haspopup='true'
          color='inherit'
        >
          <AccountCircle />
        </IconButton>
        <A href='/profile'>
          <span>Profile</span>
        </A>
      </MenuItem>
      <MenuItem>
        <IconButton aria-label='sign out of current user' color='inherit'>
          <ExitToAppIcon />
        </IconButton>
        <A href='/logout'>
          <span onClick={handleMenuClose}>Logout</span>
        </A>
      </MenuItem>
    </Menu>
  );

  useEffect(() => {
    if (state.orgId !== null) {
      if (!hasAuthorization(state.userRoles, [1, 2])) {
        console.log('here');
        setOrgSelectDisabled(true);
      }

      getOrg(cookies, state.orgId).then(response => {
        adminProps.setSelectedOrg(response.data);
      });

      if (state.accessToParks.length > 0) {
        fetchParksForOrg(cookies, state.orgId).then(data => {
          if (typeof data === 'undefined') {
            return;
          } else {
            const parkList = data.map(park => {
              return new Park(
                park.ParkId,
                park.Name,
                park.Address,
                park.City,
                park.State,
                park.Zip,
                park.Latitude,
                park.Longitude,
                park.Zoom
              );
            });

            setParks(parkList);

            if (state.accessToParks.length > 1) {
              setParkSelectDisabled(false);
            }

            setSelectedPark(parkList[0]);
            adminProps.setSelectedPark(parkList[0]);
          }
        });
      }
    }
  }, [state]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (cookies.token) {
      getOrganizations(cookies).then(data => {
        if (typeof data === 'undefined') {
          return;
        } else {
          const organizationList = data.map(org => {
            return new Organization(
              org.Id,
              org.Name,
              org.Address,
              org.City,
              org.State,
              org.Zip,
              org.ContactEmail,
              org.ContactPhoneNumber
            );
          });
          setOrganizations(organizationList);
        }
      });
    }
  }, [cookies]);

  useEffect(() => {
    if (adminProps.selectedOrg) {
      fetchParksForOrg(cookies, adminProps.selectedOrg.Id).then(data => {
        if (typeof data === 'undefined') {
          return;
        } else {
          const parkList = data.map(park => {
            return new Park(
              park.ParkId,
              park.Name,
              park.Address,
              park.City,
              park.State,
              park.Zip,
              park.Latitude,
              park.Longitude,
              park.Zoom
            );
          });
          setParks(parkList);

          if (state.accessToParks.length > 0) {
            setParkSelectDisabled(false);
          }
        }
      });
    }
  }, [adminProps.selectedOrg]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {hidden ? null : (
        <AppBar
          position='fixed'
          color='primary'
          className={clsx(classes.appBar, {
            [classes.appBarShift]: shift,
          })}
        >
          <Toolbar>
            <Typography className={classes.title} variant='h6' noWrap>
              <img src={brand} height='50px' alt='TraKid Company Logo' />
            </Typography>

            {/* Environment Internal */}
            <Typography className={classes.title} variant='subtitle1' noWrap>
              <span hidden={getEnvironment() !== 'STAGING'}>
                <StorageIcon /> STAGING
              </span>
              <span hidden={getEnvironment() !== 'QUALITY'}>
                <StorageIcon /> QUALITY
              </span>
              <span hidden={getEnvironment() !== 'DEVELOPMENT'}>
                <StorageIcon /> DEVELOPMENT
              </span>
              <span hidden={getEnvironment() !== 'LOCAL'}>
                <StorageIcon /> LOCAL
              </span>
            </Typography>

            <div className={classes.grow} />
            <Typography className={classes.title} variant='subtitle1' noWrap>
              {adminProps.selectedPark
                ? `Current Park: ${adminProps.selectedPark.Name}`
                : null}
            </Typography>

            <div className={classes.sectionDesktop}>
              <IconButton
                aria-label='park selection'
                color='inherit'
                aria-controls={parkPicker}
                aria-haspopup='true'
                onClick={handleParkMenuOpen}
              >
                <AccountTreeIcon />
              </IconButton>

              <IconButton
                edge='end'
                aria-label='account of current user'
                aria-controls={menuId}
                aria-haspopup='true'
                onClick={handleProfileMenuOpen}
                color='inherit'
              >
                <AccountCircle />
              </IconButton>
            </div>

            <div className={classes.sectionMobile}>
              <IconButton
                aria-label='show more'
                aria-controls={mobileMenuId}
                aria-haspopup='true'
                onClick={handleMobileMenuOpen}
                color='inherit'
              >
                <MoreIcon />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
      )}

      {renderMobileMenu}
      {renderAccountMenu}
      {renderParkMenu}
    </>
  );
}

PrimaryAppBar.propTypes = {
  hidden: PropTypes.bool,
  adminProps: PropTypes.object,
  shift: PropTypes.bool,
};

export default PrimaryAppBar;
