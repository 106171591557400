import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { HexColorPicker } from 'react-colorful';

import {
  Grid,
  Avatar,
  Popover,
  TextField,
  Typography,
  Button,
} from '@material-ui/core';

const WaypointLocationForm = ({
  currentWaypoint,
  handleChange,
  handleUpdateCords,
  updateRadius,
  latitudeError,
  longitudeError,
  radiusError,
  handleColorPickerClick,
  handleColorPickerClose,
  handleColorUpdate,
  openColorPicker,
  colorPickerEl,
}) => {
  const [latitude, setLatitude] = useState(currentWaypoint.Latitude);
  const [longitude, setLongitude] = useState(currentWaypoint.Longitude);

  useEffect(() => {
    setLatitude(currentWaypoint.Latitude);
    setLongitude(currentWaypoint.Longitude);
  }, [currentWaypoint]);

  const updateCords = () => {
    handleUpdateCords(latitude, longitude);
  };

  return (
    <form>
      <Grid
        container
        spacing={2}
        direction='column'
        justify='center'
        alignItems='stretch'
      >
        <Grid item>
          <TextField
            onChange={e => setLatitude(e.target.value)}
            name='Latitude'
            value={latitude}
            label='Latitude'
            id='latitude-input'
            helperText={latitudeError.show ? latitudeError.msg : ''}
            error={latitudeError.show}
            variant='outlined'
            fullWidth
          />
        </Grid>

        <Grid item>
          <TextField
            onChange={e => setLongitude(e.target.value)}
            name='Longitude'
            value={longitude}
            label='Longitude'
            id='longitude-input'
            helperText={longitudeError.show ? longitudeError.msg : ''}
            error={longitudeError.show}
            variant='outlined'
            fullWidth
          />
        </Grid>

        <Grid item style={{ alignSelf: 'flex-end' }}>
          <Button variant='contained' color='primary' onClick={updateCords}>
            Set Cords
          </Button>
        </Grid>

        <Grid item style={{ alignSelf: 'baseline' }}>
          <TextField
            name='Radius'
            value={currentWaypoint.Radius}
            label='Radius'
            id='radius-input'
            helperText={radiusError.show ? radiusError.msg : ''}
            error={radiusError.show}
            onChange={e =>
              updateRadius(e.target.value, currentWaypoint.Proximity)
            }
            type='number'
            min='1'
            variant='outlined'
          />
        </Grid>

        <Grid item style={{ alignSelf: 'baseline' }}>
          <Grid container spacing={2}>
            <Grid item xs={7}>
              <TextField
                id='waypoint-color'
                label='Color'
                name='Color'
                helperText=''
                variant='outlined'
                value={currentWaypoint.Color}
                onChange={e => handleChange(e.target)}
              />
            </Grid>
            <Grid item xs={5}>
              <Avatar
                style={{
                  backgroundColor: currentWaypoint.Color,
                  marginTop: '7px',
                  cursor: 'pointer',
                }}
                onClick={handleColorPickerClick}
              >
                &nbsp;
              </Avatar>
            </Grid>
          </Grid>
          <Popover
            open={openColorPicker}
            anchorEl={colorPickerEl}
            onClose={handleColorPickerClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <Typography>
              <HexColorPicker
                color={currentWaypoint.Color}
                onChange={handleColorUpdate}
              />
            </Typography>
          </Popover>
        </Grid>
      </Grid>
    </form>
  );
};

WaypointLocationForm.propTypes = {
  currentWaypoint: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleUpdateCords: PropTypes.func.isRequired,
  updateRadius: PropTypes.func.isRequired,
  latitudeError: PropTypes.object.isRequired,
  longitudeError: PropTypes.object.isRequired,
  radiusError: PropTypes.object.isRequired,
  handleColorPickerClick: PropTypes.func.isRequired,
  handleColorPickerClose: PropTypes.func.isRequired,
  handleColorUpdate: PropTypes.func.isRequired,
  openColorPicker: PropTypes.bool.isRequired,
  colorPickerEl: PropTypes.element,
};

export default WaypointLocationForm;
