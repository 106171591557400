import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
  },
  container: {
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

function MapPanel({ children, value, index, ...other }) {
  const classes = useStyles();

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      className={classes.root}
      {...other}
    >
      {value === index && (
        <Box p={0} className={classes.container}>
          {children}
        </Box>
      )}
    </div>
  );
}

MapPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default MapPanel;
