import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';

import AuthContext from 'context/Auth/auth';
import InfoButton from 'components/common/buttons/InfoButton';
import { ToolCardForm } from '../ToolCardForm';

import { getAppLogs } from 'services/Tools.Service';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  Grid,
  Card,
  CardActions,
  CardContent,
  Input,
  InputLabel,
  FormControl,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexGrow: 1,
  },
  toolCard: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    padding: '10px',
  },
  container: {
    maxHeight: 440,
  },
}));

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

function LogTools({ triggerSnack, setMessage, setSeverity }) {
  const context = useContext(AuthContext);
  const classes = useStyles();

  // GET APPLICATION LOGS
  const [phoneNumber, setPhoneNumber] = useState('');
  const [count, setCount] = useState(500);
  const [disableGetLogsBtn, setDisableGetLogsBtn] = useState(true);
  const [logs, setLogs] = useState([]);

  const handlePhoneNumberUpdate = phoneNumber => {
    setPhoneNumber(phoneNumber);
    setDisableGetLogsBtn(false);
  };

  const handleCountUpdate = count => {
    setCount(count);
  };

  const handleGetAppLogs = () => {
    console.log('here');
    getAppLogs(context, phoneNumber, count)
      .then(response => {
        setLogs(response.data);
        setSeverity('success');
        setMessage('Successfully Fetched Logs');
        triggerSnack(true);
      })
      .catch(error => {
        const err = error.toJSON();
        setSeverity('error');
        setMessage(err.message);
        triggerSnack(true);
      });
  };

  return (
    <>
      <Grid container spacing={2} className={classes.root}>
        {/* APPLICATION LOGS */}
        <Grid item xs={4}>
          <Card className={classes.toolCard}>
            <CardContent>
              <ToolCardForm actionLabel='Get Application Logs'>
                <FormControl>
                  <InputLabel htmlFor='logsPhoneNumber'></InputLabel>
                  <Input
                    id='logsPhoneNumber'
                    placeholder='Phone Number'
                    value={phoneNumber}
                    onChange={e => handlePhoneNumberUpdate(e.target.value)}
                    type='text'
                  />
                </FormControl>
                <FormControl>
                  <InputLabel htmlFor='logsCount'></InputLabel>
                  <Input
                    id='logsCount'
                    placeholder='Count'
                    value={count}
                    onChange={e => handleCountUpdate(e.target.value)}
                    type='number'
                    inputProps={{ min: 1 }}
                  />
                </FormControl>
              </ToolCardForm>
            </CardContent>
            <CardActions>
              <InfoButton
                onClick={handleGetAppLogs}
                disabled={disableGetLogsBtn}
              >
                Get Logs
              </InfoButton>
            </CardActions>
          </Card>
        </Grid>

        <Grid item xs={8}>
          <TableContainer component={Paper} className={classes.container}>
            <Table
              className={classes.table}
              stickyHeader
              aria-label='logs table'
            >
              <TableHead>
                <TableRow>
                  <StyledTableCell>Account ID</StyledTableCell>
                  <StyledTableCell>Message</StyledTableCell>
                  <StyledTableCell>Message Type</StyledTableCell>
                  <StyledTableCell>Created At</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {logs.map(log => (
                  <TableRow key={log.createdAt}>
                    <TableCell>{log.accountId}</TableCell>
                    <TableCell>{log.message}</TableCell>
                    <TableCell>{log.messageType}</TableCell>
                    <TableCell>{log.createdAt}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  );
}

LogTools.propTypes = {
  triggerSnack: PropTypes.func.isRequired,
  setMessage: PropTypes.func.isRequired,
  setSeverity: PropTypes.func.isRequired,
};

export default LogTools;
