import { navigate } from 'hookrouter';

export const hasAuthorization = (
  userRoles: number[],
  neededRoles: number[]
): boolean => {
  let allowed = false;

  neededRoles.forEach(role => {
    if (userRoles.indexOf(role) > -1) {
      allowed = true;
    }
  });

  return allowed;
};

export const checkAuthorization = (
  userRoles: number[],
  neededRoles: number[]
): void => {
  if (!hasAuthorization(userRoles, neededRoles)) {
    navigate('/not-authorized');
  }
};
