import { IActivity } from 'models/IActivity';
import { IActivityQuestion } from 'models/IActivityQuestion';

import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

// Paths to Default Images must be absolute.
import activityBg from '../../../../../images/cms/activity/activity.png';
import defaultImg from '../../../../../images/cms/activity/no-image-default.png';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
    background: `url(${activityBg}) no-repeat center`,
    backgroundSize: 'contain',
    height: '700px',

    '& > *': {
      boxSizing: 'content-box',
      width: '320px',
    },
  },
  pointImage: {
    display: 'grid',
    maxWidth: '320px',
    marginTop: '74px',
  },
  image: {
    justifySelf: 'center',
    maxHeight: '155px',
    maxWidth: '320px',
    borderRadius: '10px',
  },
  header: {
    textAlign: 'left',

    '& > h3': {
      color: '#000',
      padding: '0 10px',
    },
  },
  question: {
    textAlign: 'left',

    '& > p': {
      color: '#000',
      padding: '0 10px',
    },
  },
  answers: {
    flexGrow: 2,
    marginBottom: '112px',
    display: 'grid',

    '& > div': {
      alignSelf: 'center',
      border: '2px solid',
      borderColor: theme.palette.primary.main,
      borderRadius: '10px',
      color: theme.palette.primary.main,
      fontWeight: 700,
      margin: '0px 6px',
      padding: '8px 0',
    },
  },
}));

interface IProps {
  currentActivity: IActivity;
  activityImage: string | undefined;
  currentQuestion: IActivityQuestion;
}
function MultiChoicePreview(props: IProps) {
  const classes = useStyles();

  return (
    <>
      <Grid container className={classes.root}>
        <Grid item className={classes.pointImage}>
          {props.activityImage !== undefined ? (
            <img
              src={props.activityImage}
              alt='Point Imagery'
              className={classes.image}
            />
          ) : (
            <img
              src={defaultImg}
              alt='Default Imagery'
              className={classes.image}
            />
          )}
        </Grid>
        <Grid item className={classes.header}>
          {props.currentActivity.HeaderText !== '' ? (
            <h3>{props.currentActivity.HeaderText}</h3>
          ) : (
            <h3>
              <em>Header Text Placeholder</em>
            </h3>
          )}
        </Grid>
        <Grid item className={classes.question}>
          {props.currentActivity.QuestionText !== '' ? (
            <p>{props.currentActivity.QuestionText}</p>
          ) : (
            <p>
              <em>Question Text Placeholder</em>
            </p>
          )}
        </Grid>
        <Grid item className={classes.answers}>
          {props.currentQuestion.Options.map(option => {
            return <div key={option.Id}>{option.OptionText}</div>;
          })}
        </Grid>
      </Grid>
    </>
  );
}

export default MultiChoicePreview;
