import PropTypes from 'prop-types';

import CustomInput from './CustomInput';

import { Grid, Typography, TextField } from '@material-ui/core';

const WaypointDetailsForm = ({
  currentWaypoint,
  handleChange,
  shortNameError,
}) => (
  <form>
    <Grid
      container
      spacing={2}
      direction='column'
      justify='center'
      alignItems='stretch'
    >
      <Grid item>
        <TextField
          onChange={e => handleChange(e.target)}
          name='ShortName'
          value={currentWaypoint.ShortName}
          label='Short Name'
          id='short-name-input'
          helperText={shortNameError ? 'Cannot be blank' : ''}
          error={shortNameError}
          fullWidth
          variant='outlined'
        />
      </Grid>
      <Grid item>
        <CustomInput
          handleChange={handleChange}
          name='LongName'
          value={currentWaypoint.LongName}
          label='Long Name'
          id='long-name-input'
          multiline
          rows={4}
          fullWidth
        />
      </Grid>
      <Grid item>
        <CustomInput
          handleChange={handleChange}
          name='Description'
          value={currentWaypoint.Description}
          label='Description'
          id='description-input'
          multiline
          rows={4}
          fullWidth
        />
      </Grid>
      <Grid item>
        <Typography align='left'>
          *Image can be uploaded in the 'Activity' tab.
        </Typography>
      </Grid>
    </Grid>
  </form>
);

WaypointDetailsForm.propTypes = {
  currentWaypoint: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  shortNameError: PropTypes.bool.isRequired,
};

export default WaypointDetailsForm;
