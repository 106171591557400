import axios from 'axios';

import { getBaseUrl, getAPIVersion } from '../config';

const version = getAPIVersion();
const path = `/api/${version}/Images/`;

axios.defaults.headers.post['Content-Type'] = 'application/json';

const IMAGES = {
  FETCH: {
    IMAGE: 'getimage',
    SCAVENGER: 'getscavengerpointimage',
  },
  SET: {
    PARK: 'setparkimage',
    SCAVENGER: 'setscavengerpointimage',
  },
};

export function getImage(context, imageId, imageType) {
  return axios({
    method: 'post',
    url: getBaseUrl() + path + IMAGES.FETCH.IMAGE,
    headers: { Authorization: `Bearer ${context.token}` },
    data: {
      imageId: imageId,
      imageType: imageType,
    },
  });
}

export function getPointImage(context, parkId, zoneId, id, imageId) {
  return axios({
    method: 'post',
    url: getBaseUrl() + path + IMAGES.FETCH.SCAVENGER,
    headers: { Authorization: `Bearer ${context.token}` },
    data: {
      parkId: parkId,
      zoneId: zoneId,
      scavengerPointId: id,
      imageId: imageId,
    },
  });
}

export function setPointImage(context, parkId, zoneId, id, image) {
  return axios({
    method: 'post',
    url: getBaseUrl() + path + IMAGES.SET.SCAVENGER,
    headers: { Authorization: `Bearer ${context.token}` },
    data: {
      parkId: parkId,
      zoneId: zoneId,
      id: id,
      image: image,
    },
  });
}

export function setParkImage(context, parkId, image) {
  return axios({
    method: 'post',
    url: getBaseUrl() + path + IMAGES.SET.PARK,
    headers: { Authorization: `Bearer ${context.token}` },
    data: {
      parkId: parkId,
      imageType: 6,
      image: image,
    },
  });
}

export function setCompassImage(context, parkId, image) {
  return axios({
    method: 'post',
    url: getBaseUrl() + path + IMAGES.SET.PARK,
    headers: { Authorization: `Bearer ${context.token}` },
    data: {
      parkId: parkId,
      imageType: 7,
      image: image,
    },
  });
}
