import { useState, useEffect, useContext } from 'react';

import AuthContext from 'context/Auth/auth';
import { UserContext } from 'context/UserContext';

import { IOrganization } from 'models/IOrganization';
import { getOrg } from 'services/OrgsService';
import { IPark } from 'models/IPark';
import { fetchParksForOrg } from 'services/Parks.Service';

import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Card,
  CardContent,
  Typography,
  CircularProgress,
} from '@material-ui/core';

import BusinessIcon from '@material-ui/icons/Business';
import PublicIcon from '@material-ui/icons/Public';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

export default function Overview() {
  const classes = useStyles();
  const authContext = useContext(AuthContext);
  const { state } = useContext(UserContext);
  const [loading, setLoading] = useState(false);

  const [organization, setOrganization] = useState<IOrganization | undefined>(
    undefined
  );
  const [accessParks, setAccessParks] = useState<IPark[]>();

  useEffect(() => {
    setLoading(true);

    if (state.orgId !== null) {
      getOrg(authContext, state.orgId)
        .then(response => {
          setOrganization(response.data);

          fetchParksForOrg(authContext, state.orgId)
            .then(parks => {
              if (state.accessToParks.length > 0) {
                let listOfParks: IPark[] = [];
                parks.forEach((park: IPark) => {
                  if (state.accessToParks.includes(park.ParkId)) {
                    listOfParks.push(park);
                  }
                });
                setAccessParks(listOfParks);
              } else {
                setAccessParks([]);
              }
            })
            .catch(error => {
              console.error(error);
            })
            .finally(() => {
              setLoading(false);
            });
        })
        .catch(error => {
          console.error(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [state]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          {typeof organization !== undefined ? (
            <Grid container spacing={2}>
              <Grid item>
                <Card className={classes.root}>
                  <CardContent>
                    <BusinessIcon />
                    <Typography
                      className={classes.title}
                      color='textSecondary'
                      gutterBottom
                    >
                      Organization
                    </Typography>
                    <Typography className={classes.pos} color='textSecondary'>
                      {organization?.Name}
                    </Typography>
                    <Typography variant='body2' component='p'>
                      {`${organization?.Address} ${organization?.City}, ${organization?.State} ${organization?.Zip}`}
                    </Typography>
                    <Typography variant='body2' component='p'>
                      {`${organization?.ContactEmail} / ${organization?.ContactPhoneNumber}`}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          ) : null}

          <Grid container spacing={2}>
            {accessParks !== undefined &&
              accessParks?.map((park: IPark) => {
                return (
                  <Grid item key={park.ParkId}>
                    <Card className={classes.root}>
                      <CardContent>
                        <PublicIcon />
                        <Typography
                          className={classes.title}
                          color='textSecondary'
                          gutterBottom
                        >
                          Park
                        </Typography>
                        <Typography
                          className={classes.pos}
                          color='textSecondary'
                        >
                          {park.Name}
                        </Typography>
                        <Typography variant='body2' component='p'>
                          {`${park.Address} ${park.City}, ${park.State} ${park.Zip}`}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                );
              })}
          </Grid>
        </>
      )}
    </>
  );
}
