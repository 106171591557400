import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'react-bootstrap';

import { addTrackerEntry } from 'services/TrackersService';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import FormControl from '@material-ui/core/FormControl';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';

export default function TrackerSpoofer({ listKey, spoofer, deleteCallback }) {
  const [track, setTrack] = useState([]);
  const [id, setId] = useState();
  const [batteryValue, setBatteryValue] = useState('Y');
  const [, setTemp] = useState();

  const batteryValueRef = useRef();
  batteryValueRef.current = batteryValue;

  useEffect(() => {
    let timer = null;

    if (id && track.length > 0) {
      timer = setInterval(() => {
        let point = track.shift();
        track.push(point);
        addTrackerEntry(
          spoofer.TrackerId,
          batteryValueRef.current,
          point.Latitude,
          point.Longitude
        );
      }, spoofer.Interval * 1000);
    } else {
      clearInterval(timer);
    }
    return () => clearInterval(timer);
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setTemp(batteryValue);
  }, [batteryValue]);

  useEffect(() => {
    setTrack([...spoofer.Track.Track]);
    setId(spoofer.TrackerId);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function deleteSpoofer() {
    setId(null);
    deleteCallback(listKey);
  }

  return (
    <TableRow key={spoofer.TrackerId}>
      <TableCell component='th' scope='row'>
        {spoofer.TrackerId}
      </TableCell>
      <TableCell>{spoofer.Interval}</TableCell>
      <TableCell>{spoofer.Track.Name}</TableCell>
      <TableCell>
        <form>
          <FormControl
            id='batteryValue'
            placeholder='Battery Value'
            value={batteryValue}
            onChange={e => setBatteryValue(e.target.value)}
          >
            <InputLabel htmlFor='batteryValue'>Battery Value</InputLabel>
            <Input
              id='batteryValue'
              value={batteryValue}
              placeholder='Battery Value'
              onChange={e => setBatteryValue(e.target.value)}
            />
          </FormControl>
        </form>
      </TableCell>
      <TableCell>
        <Button variant='danger' onClick={deleteSpoofer}>
          <DeleteForeverIcon />
        </Button>
      </TableCell>
    </TableRow>
  );
}
