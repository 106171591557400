import { ChangeEvent } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Grid, TextField, Button } from '@material-ui/core';
import {
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
} from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  subContainer: {
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  image: {
    color: '#2ecc71',
  },
  missingImage: {
    color: '#e74c3c',
  },
}));

interface IProps {
  currentActivity: any;
  currentWaypointImage: any;
  handleChange: (e: EventTarget, b: boolean) => void;
  handleImageUpload: (e: ChangeEvent<HTMLInputElement>) => void;
}
const WaypointActivityForm = (props: IProps) => {
  const classes = useStyles();

  return (
    <Grid
      container
      spacing={2}
      direction='column'
      justify='center'
      alignItems='stretch'
    >
      <Grid item xs={12}>
        <Grid container spacing={2} className={classes.subContainer}>
          <Grid item>
            <Button variant='contained' color='default' component='label'>
              Select Image
              <input
                type='file'
                accept='.jpeg, .png, .jpg'
                hidden
                onChange={e => props.handleImageUpload(e)}
              />
            </Button>
          </Grid>
          <Grid item>
            {props.currentWaypointImage ? (
              <div>
                <CheckCircleIcon className={classes.image} />
                <span>Current Point has Image</span>
              </div>
            ) : (
              <div>
                <CancelIcon className={classes.missingImage} />
                <span>Current Waypoint is missing an Image</span>
              </div>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <TextField
          id='waypoint-headerText'
          label='Header Text'
          name='HeaderText'
          helperText=''
          variant='outlined'
          value={props.currentActivity.HeaderText}
          onChange={e => props.handleChange(e.target, true)}
          fullWidth
        />
      </Grid>
      <Grid item>
        <TextField
          id='waypoint-questionText'
          label='Question Text'
          name='QuestionText'
          helperText=''
          variant='outlined'
          value={props.currentActivity.QuestionText}
          onChange={e => props.handleChange(e.target, true)}
          fullWidth
          multiline
          rows={4}
        />
      </Grid>
    </Grid>
  );
};

export default WaypointActivityForm;
