import React, { memo } from 'react';

import { Typography, Link } from '@material-ui/core';

const Copyright = () => (
  <>
    <Typography variant='body2' color='textSecondary' align='center'>
      {'Copyright © '}
      <Link color='inherit' href='https://www.trakid.com/'>
        TRAKID
      </Link>{' '}
      {new Date().getFullYear()}
    </Typography>
  </>
);

export default memo(Copyright);
