export interface Tutorial {
  Id: number | null;
  ParkId: number | null;
  ScavengerHuntName: string;
  WelcomeText1: string;
  WelcomeText2: string;
  WelcomeText3: string;
  StateText1: string;
  StateText2: string;
  StateText3: string;
  Description1: string;
  Description2: string;
  Description3: string;
  Title1: string;
  Title2: string;
  Title3: string;
  TutorialMainImageId: number | null;
  TutorialCompassImageId: number | null;
}

export const TutorialStarter = (parkId: number | null = null): Tutorial => {
  let newTutorial: Tutorial = {
    Id: null,
    ParkId: parkId,
    ScavengerHuntName: '',
    WelcomeText1: '',
    WelcomeText2: '',
    WelcomeText3: '',
    StateText1: '',
    StateText2: '',
    StateText3: '',
    Description1: '',
    Description2: '',
    Description3: '',
    Title1: '',
    Title2: '',
    Title3: '',
    TutorialMainImageId: null,
    TutorialCompassImageId: null,
  };

  return newTutorial;
};
