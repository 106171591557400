import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#315aa9',
    },
    secondary: {
      main: '#F05837',
    },
  },
  typography: {
    fontFamily: [
      '"Source Sans Pro"',
      '"Work Sans"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
    ].join(','),
  },
});

export default theme;
