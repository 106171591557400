import React from 'react';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';

const SelectDropdown = ({
  labelId,
  label,
  items,
  onItemSelected,
  selected,
}) => (
  <>
    <InputLabel id={labelId}>{label}</InputLabel>
    <Select
      labelId={labelId}
      value={selected ? selected.Name : ''}
      onChange={() => {}}
      input={<Input />}
    >
      {items.map((item, index) => (
        <MenuItem
          key={index}
          onClick={() => onItemSelected(item)}
          value={item.Name}
        >
          {item.Name}
        </MenuItem>
      ))}
    </Select>
  </>
);

export default SelectDropdown;
