import React, { useEffect, useState, useContext } from 'react';
import ReactDOM from 'react-dom';
import { useRoutes, A, navigate } from 'hookrouter';
import { useCookies } from 'react-cookie';
import WebFontConfig from 'webfontloader';

import AppContainer from './components/AppContainer';

import { getBaseUrl } from './config';
import { useLocalStorage } from './custom-hooks';

// Context
import AuthContextProvider from './context/Auth';
import { UserContext, UserContextProvider } from 'context/UserContext';

import PrimaryAppBar from './components/common/PrimaryAppBar';
import SideNavList from './components/common/SideNavList';
// import Notification from './helpers/Notification';
import Theme from './styles/theme';

// Pages
import LoginPage from './components/pages/Login';
import NoAccess from 'components/pages/NoAccess';
import SignIn from './components/pages/Login/SignIn';
import AccountAdmin from './components/pages/AccountAdmin';
import DevTools from './components/pages/DevTools';
import Manufacturing from 'components/pages/Manufacturing';
import Dispatch from './components/pages/Dispatch';
import LostChild from './components/pages/LostChild';
import Overview from './components/pages/Overview';
import Devices from './components/pages/Devices';
import UserManagement from './components/pages/UserManagement';
import ExplorerQuest from './components/pages/ExplorerQuest';
import TrackerView from './components/pages/TrackerView';
import ZoneCMS from './components/pages/ExplorerQuest/cms/pages/ZoneCMS';
import WaypointCMS from './components/pages/ExplorerQuest/cms/pages/WayPointCMS';
import Profile from './components/pages/Profile';

import clsx from 'clsx';

import { makeStyles, useTheme, ThemeProvider } from '@material-ui/core/styles';
import { CssBaseline, Drawer, Typography, Divider } from '@material-ui/core';

import 'styles/App.css';

const url = getBaseUrl();

const Logout = () => {
  const [, , removeCookie] = useCookies(['token']);
  useEffect(() => {
    removeCookie('token');
    navigate('/', true);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return <p>Closing session</p>;
};

WebFontConfig.load({
  google: {
    families: ['Source Sans Pro', 'Work Sans'],
  },
});

const routes = {
  '/': () => <SignIn />,
  '/not-authorized': () => <NoAccess />,
  '/signin': () => <SignIn />,
  '/login': () => <LoginPage />,
  '/Login': () => <LoginPage />,
  '/logout': () => <Logout />,
  '/accounts': () => <AccountAdmin />,
  '/trakid-tools': () => <DevTools />,
  '/manufacturing': () => <Manufacturing />,
  '/dispatch': () => <Dispatch />,
  '/LostChild': () => <LostChild baseUrl={url} />,
  '/overview': () => <Overview />,
  '/explorer-quest': () => <ExplorerQuest />,
  '/explorer-quest/zone/new': () => <ZoneCMS />,
  '/explorer-quest/zone/:id': ({ id }) => <ZoneCMS zoneId={id} />,
  '/explorer-quest/zone/:zoneId/waypoint/new': ({ zoneId }) => (
    <WaypointCMS zoneId={zoneId} />
  ),
  '/explorer-quest/zone/:zoneId/waypoint/:wpId/edit': ({ zoneId, wpId }) => (
    <WaypointCMS zoneId={zoneId} wpId={wpId} />
  ),
  '/tracker': () => <TrackerView baseUrl={url} />,
  '/TrackerView/Lost': () => <TrackerView baseUrl={url} />,
  '/device-management': () => <Devices />,
  '/user-management': () => <UserManagement />,
  '/profile': () => <Profile />,
};

const drawerWidth = 240;
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  grow: {
    flexGrow: 1,
  },
}));

function App() {
  const Router = useRoutes(routes);
  const { state, dispatch } = useContext(UserContext);
  const [cookies] = useCookies(['token']); // eslint-disable-line no-unused-vars

  const [selectedOrg, setSelectedOrg] = useState(null);
  const [orgListOpen, setOrgListOpen] = useState(false);
  const [selectedPark, setSelectedPark] = useState(null);
  const [parkListOpen, setParkListOpen] = useState(false);
  const [parkBoundary, setParkBoundary] = useState([]);
  const [parkId, setParkId] = useLocalStorage('parkId', null);
  const [parkLat, setParkLat] = useLocalStorage('parkLat', null);
  const [parkLng, setParkLng] = useLocalStorage('parkLng', null);
  const [organizations, setOrganizations] = useState([]);
  const [parks, setParks] = useState([]);
  const [, setDisplayNotification] = useState(false);
  const [open, setOpen] = useState(false);

  const classes = useStyles();
  const theme = useTheme();

  const adminProps = {
    selectedOrg,
    setSelectedOrg,
    orgListOpen,
    setOrgListOpen,
    selectedPark,
    setSelectedPark,
    parkListOpen,
    setParkListOpen,
    setDisplayNotification,
    parkBoundary,
    setParkBoundary,
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (selectedPark) {
      setParkId(selectedPark.ParkId);
    }
  }, [selectedPark]); // eslint-disable-line react-hooks/exhaustive-deps

  // TODO Update nav UI
  // return Router ? (
  //   <ThemeProvider theme={Theme}>
  //     <AppContainer>
  //       <AuthContextProvider adminProps={adminProps}>
  //         {Router}
  //       </AuthContextProvider>
  //     </AppContainer>
  //   </ThemeProvider>
  // ) : (
  //   <h1>
  //     Error 404: <A href='/'>Go back</A>
  //   </h1>
  // );

  return Router ? (
    <ThemeProvider theme={Theme}>
      <CssBaseline />

      <AuthContextProvider adminProps={adminProps}>
        <div className={classes.root}>
          {/* Top Bar */}
          <PrimaryAppBar
            hidden={!cookies.token}
            adminProps={adminProps}
            shift={open}
          />

          {/* Sidebar */}
          <Drawer
            variant='permanent'
            hidden={!cookies.token}
            className={clsx(classes.drawer, {
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            })}
            classes={{
              paper: clsx({
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
              }),
            }}
          >
            <div className={classes.toolbar}>
              <Typography variant='subtitle2'>
                TRAKID &copy; 2021
                <br />
                <em>v0.3.0</em>
              </Typography>
            </div>

            <Divider />

            <SideNavList
              handleOpen={handleDrawerOpen}
              handleClose={handleDrawerClose}
              isOpen={open}
            />
          </Drawer>

          <main className={classes.content}>
            <div className={classes.toolbar} />
            {Router}
          </main>
        </div>
      </AuthContextProvider>
    </ThemeProvider>
  ) : (
    <h1>
      Error 404: <A href='/'>Go back</A>
    </h1>
  );
}

const rootElement = document.getElementById('root');
ReactDOM.render(
  <UserContextProvider>
    <App />
  </UserContextProvider>,
  rootElement
);
