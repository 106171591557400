import { Tutorial } from 'models/Tutorial';

import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

import gameStatesBg from '../../../../../images/cms/blank-screens/how-to-play-1.png';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flexGrow: 1,
    background: `url(${gameStatesBg}) no-repeat center`,
    backgroundSize: 'contain',
    height: '700px',

    '& > *': {
      boxSizing: 'content-box',
      // border: '1px solid red',
      width: '320px',
    },
  },
  headerText: {
    color: '#FFFFFF',
    textAlign: 'center',
    marginTop: '32px',
    marginBottom: 0,

    '& > h3': {
      fontSize: '1.4rem',
    },
  },
  description: {
    color: '#000000',
    textAlign: 'left',
    fontSize: '1.3rem',
    maxWidth: '300px',
    padding: '0 10px',
    marginTop: '10px',
    marginBottom: '10px',

    '& > h3': {
      fontSize: '1.35rem',
      fontWeight: 'bolder',
    },

    '& > p': {
      fontSize: '16px',
    },
  },
  gameState: {
    color: '#FFFFFF',
    textAlign: 'left',
    marginTop: '12px',
    marginBottom: '82px',
    maxWidth: '300px',
    paddingLeft: '20px',

    '& > h3': {
      fontSize: '1.4rem',
    },
  },
}));

interface Props {
  tutorial: Tutorial;
}
function GameStatesPreview({ tutorial }: Props) {
  const classes = useStyles();

  return (
    <>
      <Grid container className={classes.root}>
        <Grid item className={classes.headerText}>
          <h3>How to play</h3>
        </Grid>

        <Grid item className={classes.description}>
          <h3>Your adventure starts here!</h3>
          <p>Every adventure requires these steps!</p>
        </Grid>

        <Grid item className={classes.gameState}>
          {tutorial.StateText1 !== '' ? (
            <h3>{tutorial.StateText1}</h3>
          ) : (
            <h3>State 1</h3>
          )}
        </Grid>

        <Grid item className={classes.gameState}>
          {tutorial.StateText2 !== '' ? (
            <h3>{tutorial.StateText2}</h3>
          ) : (
            <h3>State 2</h3>
          )}
        </Grid>

        <Grid item className={classes.gameState}>
          {tutorial.StateText3 !== '' ? (
            <h3>{tutorial.StateText3}</h3>
          ) : (
            <h3>State 3</h3>
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default GameStatesPreview;
