import React, { useEffect, useRef, useState } from 'react';
import { getGoogleMapsApiKey } from '../config';

export default function DispatchMap({
  options,
  mapCenter,
  zoom,
  childMarkers,
  parentMarkers,
  staffMarkers,
  className,
  clickCallback,
  selectedChild,
}) {
  const ref = useRef();
  const [map, setMap] = useState();
  const [childMarkerList, setChildMarkerList] = useState([]);
  const [parentMarkerList, setParentMarkerList] = useState([]);
  const [staffMarkerList, setStaffMarkerList] = useState([]);
  const [legendExists, setLegendExists] = useState(false);
  const maxMarkers = 20;

  useEffect(() => {
    childMarkerList.forEach(marker => {
      marker.m.setMap(null);
      marker.m = null;
    });
    setChildMarkerList([]);

    parentMarkerList.forEach(marker => {
      marker.m.setMap(null);
      marker.m = null;
    });
    setParentMarkerList([]);

    let legend = document.getElementById('legend');

    if (map && selectedChild && !legendExists) {
      map.controls[window.google.maps.ControlPosition.LEFT_TOP].push(legend);
      setLegendExists(true);
    } else {
    }
  }, [selectedChild]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (map && mapCenter) {
      map.setCenter(mapCenter);
    }
  }, [mapCenter]);

  useEffect(() => {
    if (map && zoom) {
      map.setZoom(zoom);
    }
  }, [zoom]);

  useEffect(() => {
    if (childMarkers) {
      drawPath(
        childMarkers,
        childMarkerList,
        setChildMarkerList,
        'red',
        'https://maps.google.com/mapfiles/ms/icons/red-dot.png'
      );
    }
  }, [childMarkers]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (parentMarkers) {
      drawPath(
        parentMarkers,
        parentMarkerList,
        setParentMarkerList,
        'green',
        'https://maps.google.com/mapfiles/ms/icons/green-dot.png'
      );
    }
  }, [parentMarkers]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (staffMarkers && staffMarkers?.length > 0) {
      staffMarkers.forEach(staffMember => {
        drawPath(
          staffMember,
          staffMarkerList,
          setStaffMarkerList,
          'blue',
          'https://maps.google.com/mapfiles/ms/icons/blue-dot.png'
        );
      });
    }
  }, [staffMarkers]); // eslint-disable-line react-hooks/exhaustive-deps

  function drawPath(markers, markerList, setMarkerList, pathColor, pinLink) {
    let newMarkers = getNewMarkers(markers, markerList);

    if (newMarkers?.length > 0) {
      let updatedMarkersList = [...markerList];
      updatedMarkersList.sort((a, b) => a['i'] - b['i']);
      newMarkers
        .sort((a, b) => a['Id'] - b['Id'])
        .forEach(marker => {
          const m = new window.google.maps.Marker({
            map,
            position: { lat: marker.Latitude, lng: marker.Longitude },
            title: `${marker.Id}`,
          });
          updatedMarkersList.push({ i: marker.Id, m: m });
          if (updatedMarkersList.length > maxMarkers) {
            let rem = updatedMarkersList.shift();
            if (rem) {
              rem.m.setMap(null);
              rem.m = null;
            }
          }
        });
      for (let i = updatedMarkersList.length - 1; i > -1; i--) {
        if (i === updatedMarkersList.length - 1) {
          updatedMarkersList[i].m.setOpacity(1);
          updatedMarkersList[i].m.setIcon(pinLink);
        } else {
          let op = (i / maxMarkers) * 0.5 + 0.2;
          updatedMarkersList[i].m.setOpacity(op);
          updatedMarkersList[i].m.setIcon({
            path: window.google.maps.SymbolPath.CIRCLE,
            scale: 5,
            strokeWeight: 3,
            strokeColor: pathColor,
            fillColor: pathColor,
            fillOpacity: op,
          });
        }
      }
      setMarkerList(updatedMarkersList);
    }
  }

  function handleClickEvent(mapsMouseEvent) {
    if (clickCallback) {
      let step1 = mapsMouseEvent.latLng.toString();
      let step2 = step1.slice(1, step1.length - 1);
      let step3 = step2.split(', ');
      let result = {
        lat: parseFloat(step3[0].slice(0, step3[0].indexOf('.') + 8)),
        lon: parseFloat(step3[1].slice(0, step3[1].indexOf('.') + 8)),
      };
      clickCallback(result);
      //setScavengerMarker(result);
    }
  }

  useEffect(() => {
    const onLoad = () => {
      let newMap = new window.google.maps.Map(ref.current, options);
      newMap.addListener('click', function (mapsMouseEvent) {
        handleClickEvent(mapsMouseEvent);
      });
      setMap(newMap);
    };
    if (!window.google) {
      const script = document.createElement(`script`);
      script.src =
        `https://maps.googleapis.com/maps/api/js?key=` + getGoogleMapsApiKey();
      document.head.append(script);
      script.addEventListener(`load`, onLoad);
      return () => script.removeEventListener(`load`, onLoad);
    } else onLoad();
  }, [options]); // eslint-disable-line react-hooks/exhaustive-deps

  function getNewMarkers(markers, markerList) {
    let result = [];
    markers.forEach(marker => {
      let found = markerList.find(m => m.i === marker.Id);
      if (!found) {
        result.push(marker);
      }
    });
    return result;
  }

  return (
    <div
      style={{
        width: '100%',
        height: `100%`,
        margin: `0em 0`,
        borderRadius: `0.5em`,
      }}
      {...{ ref, className }}
    />
  );
}

function legendStuff() {
  return <div id='legend'>asdfasdfasdfasdfasdfasdfasdfasdf</div>;
}

DispatchMap.defaultProps = {
  options: {
    center: { lat: 35.776874, lng: -78.645364 },
    zoom: 18,
  },
};
