import React, { useState, useEffect, useContext } from 'react';
import { Row, Col, Button, Container, Modal } from 'react-bootstrap';

import Dropdown from '../../Dropdown';
import AuthContext from '../../../context/Auth/auth';
import { getAccounts } from '../../../services/AccountsService';
import {
  getTrackers,
  addTracker,
  exchangeTracker,
  updateTracker,
  removeTracker,
} from '../../../services/TrackersService';
import { accountTitle } from '../../../helpers/DropdownTitle';

import './AccountAdmin.css';

export default function AccountAdmin() {
  const [accounts, setAccounts] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [trackers, setTrackers] = useState([]);
  const [selectedTracker, setSelectedTracker] = useState([]);
  const [parkListOpen] = useState(false);
  const [addTrackerButtonDisabled, setAddTrackerButtonDisabled] = useState(
    true
  );
  const [showTrackerModal, setShowTrackerModal] = useState(false);
  const [modifiableTracker, setModifiableTracker] = useState(null);

  const context = useContext(AuthContext);

  useEffect(() => {
    if (selectedAccount) {
      setAddTrackerButtonDisabled(false);
      getTrackers(selectedAccount.AccountId, setTrackers);
    } else {
      setAddTrackerButtonDisabled(true);
      setTrackers([]);
    }
  }, [selectedAccount]);

  useEffect(() => {
    setSelectedAccount(null);
    getAccounts(setAccounts);
  }, [context.tbProps.selectedPark]);

  useEffect(() => {
    getAccounts(setAccounts);
  }, []);

  function handleClose() {
    setShowTrackerModal(false);
    setSelectedTracker(null);
    setModifiableTracker(null);
  }

  function handleSave() {
    if (!selectedTracker.TrackerId) {
      modifiableTracker.AccountId = selectedAccount.AccountId;
      addTracker(modifiableTracker, data => {
        setTrackers([...trackers, data]);
      });
    } else {
      if (selectedTracker?.TrackerId !== modifiableTracker?.TrackerId) {
        exchangeTracker(
          selectedTracker?.TrackerId,
          modifiableTracker?.TrackerId,
          selectedAccount.AccountId,
          data => {
            selectedTracker.TrackerId = data.TrackerId;
            const copy = [...trackers];
            setTrackers(copy);
          }
        );
      }
      if (
        selectedTracker?.Nickname !== modifiableTracker?.Nickname ||
        selectedTracker?.Gender !== modifiableTracker?.Gender ||
        selectedTracker?.SpecialNeeds !== modifiableTracker?.SpecialNeeds
      ) {
        updateTracker(modifiableTracker, data => {
          selectedTracker.Nickname = data.Nickname;
          modifiableTracker.Nickname = data.Nickname;
          selectedTracker.Gender = data.Gender;
          modifiableTracker.Gender = data.Gender;
          selectedTracker.SpecialNeeds = data.SpecialNeeds;
          modifiableTracker.SpecialNeeds = data.SpecialNeeds;
          const copy = [...trackers];
          setTrackers(copy);
        });
      }
    }

    setShowTrackerModal(false);
  }

  function trackerModalTitle() {
    if (modifiableTracker?.TrackerId) {
      return 'Modify Tracker: ' + modifiableTracker?.TrackerId;
    } else {
      return 'Add Tracker';
    }
  }

  function openTrackerModal(tracker) {
    setSelectedTracker(tracker);
    let clone = JSON.parse(JSON.stringify(tracker));
    setModifiableTracker(clone);
    setShowTrackerModal(true);
  }

  function removeTrackerFromAccount(tracker) {
    removeTracker(tracker.TrackerId, tracker.AccountId, data => {
      setTrackers(trackers.filter(item => item.TrackerId !== data.TrackerId));
    });
  }

  return (
    <div className='App'>
      <Container fluid>
        <Row>
          <Col>
            <Row>
              <Col xs={1} sm={1} md={1} lg={1} xl={1}>
                Account:
              </Col>
              <Col xs={2} sm={2} md={2} lg={2} xl={2}>
                <Dropdown
                  items={accounts}
                  toggleItem={setSelectedAccount}
                  titleCreator={accountTitle}
                  initialTitle={'Select Account'}
                  closeDropdown={parkListOpen}
                  disabled={context.tbProps.selectedPark === null}
                />
              </Col>
              <Col xs={2} sm={2} md={2} lg={2} xl={2}>
                <Button
                  variant='success'
                  disabled={addTrackerButtonDisabled}
                  onClick={() => {
                    openTrackerModal({});
                  }}
                >
                  Add Tracker
                </Button>
              </Col>
              <Col xs={7} sm={7} md={7} lg={7} xl={7}></Col>
            </Row>
            <Row>
              <Col>
                <div>
                  <div>
                    <Row className='header-row'>
                      <Col>TrackerId</Col>
                      <Col>Nickname</Col>
                      <Col>Gender</Col>
                      <Col>Special Needs</Col>
                      <Col>Status</Col>
                      <Col>Battery Status</Col>
                      <Col></Col>
                    </Row>
                  </div>
                  {trackers.map((item, index) => (
                    <Row key={index} className='list-row'>
                      <Col>{item.TrackerId}</Col>
                      <Col>{item.Nickname}</Col>
                      <Col>{item.Gender}</Col>
                      <Col>{item.SpecialNeeds}</Col>
                      <Col>{item.Status}</Col>
                      <Col>{item.BatteryStatus}</Col>
                      <Col>
                        <Row>
                          <Col>
                            <Button onClick={() => openTrackerModal(item)}>
                              Modify
                            </Button>
                          </Col>
                          <Col>
                            <Button
                              variant='danger'
                              onClick={() => removeTrackerFromAccount(item)}
                            >
                              Remove
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  ))}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      <Modal show={showTrackerModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{trackerModalTitle()}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className='modal-section-header'>Exchange TrackerId</div>
            <form>
              <Row>
                <Col>
                  <label className='modal-text'>TrackerId:</label>
                </Col>
                <Col>
                  <label>
                    <input
                      type='text'
                      defaultValue={modifiableTracker?.TrackerId}
                      onChange={e =>
                        (modifiableTracker.TrackerId = e.target.value)
                      }
                    />
                  </label>
                </Col>
              </Row>
            </form>
          </div>
          <div>
            <div className='modal-section-header'>Edit Tracker Properties</div>
            <form>
              <Row>
                <Col>
                  <label className='modal-text'>Nickname:</label>
                </Col>
                <Col>
                  <label className='modal-text'>
                    <input
                      type='text'
                      defaultValue={modifiableTracker?.Nickname}
                      onChange={e =>
                        (modifiableTracker.Nickname = e.target.value)
                      }
                    />
                  </label>
                </Col>
              </Row>
              <Row>
                <Col>
                  <label className='modal-text'>Gender:</label>
                </Col>
                <Col>
                  <label className='modal-text'>
                    <select
                      defaultValue={
                        modifiableTracker?.Gender
                          ? modifiableTracker.Gender
                          : '0'
                      }
                      onChange={e =>
                        (modifiableTracker.Gender = e.target.value)
                      }
                    >
                      <option value='0' disabled hidden>
                        Please Choose...
                      </option>
                      <option value='1'>Female</option>
                      <option value='2'>Male</option>
                      <option value='3'>Prefer Not To Say</option>
                    </select>
                  </label>
                </Col>
              </Row>
              <Row>
                <Col>
                  <label className='modal-text'>Special Needs:</label>
                </Col>
                <Col>
                  <label className='modal-text'>
                    <textarea
                      defaultValue={modifiableTracker?.SpecialNeeds}
                      onChange={e =>
                        (modifiableTracker.SpecialNeeds = e.target.value)
                      }
                    />
                  </label>
                </Col>
              </Row>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            Close
          </Button>
          <Button variant='primary' onClick={handleSave}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
