import { useContext, useEffect } from 'react';
import { navigate } from 'hookrouter';
import { UserContext } from 'context/UserContext';
import { hasAuthorization } from 'helpers/HasAuthorization';

export default function Devices() {
  const { state, dispatch } = useContext(UserContext);

  // Check for Authorization
  useEffect(() => {
    if (state.orgId !== null) {
      if (!hasAuthorization(state.userRoles, [1, 2, 3, 4, 10])) {
        navigate('/not-authorized');
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <h1>Device grid to be here</h1>
    </>
  );
}
