import React, { useEffect, useContext, useState } from 'react';
import styled from 'styled-components';
import { navigate } from 'hookrouter';

import AuthContext from 'context/Auth/auth';
import CMSContext from 'context/CMSContext';
import { getZones, deleteZone, updateZoneStatus } from 'services/ZonesService';
import { Zone } from 'models/Zone';
import { getPoints, deletePoint } from 'services/Waypoint.Service';
import { Point } from 'models/Point';
import ConfirmationDialog from 'helpers/ConfirmationDialog';
import WaypointList from './WaypointList';

import { makeStyles } from '@material-ui/core/styles';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AccordionActions,
  Typography,
  Button,
  Grid,
  Avatar,
  CircularProgress,
  FormControlLabel,
  Switch,
  Backdrop,
} from '@material-ui/core';

import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  ExpandMore as ExpandMoreIcon,
} from '@material-ui/icons';

const NoLocationsText = styled.div`
  text-align: center;
  font-style: italic;
  font-size: 1.35em;
  margin-top: 65px;
`;

//#region Styles
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#FFF',
  },
  table: {},
  grid: {
    '& > *': {
      alignSelf: 'center',
    },
  },
  heading: {
    paddingLeft: '10px',
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));
//#endregion

function ZoneList({ onZoneSelected }) {
  const authContext = useContext(AuthContext);
  const cmsContext = useContext(CMSContext);

  const [createButtonDisabled, setCreateButtonDisabled] = useState(true);
  const [locationsInPark, setLocationsInPark] = useState([]);
  const [pointsInZone, setPointsInZone] = useState([]);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showConfirmationForPoint, setShowConfirmationForPoint] =
    useState(false);
  const [zoneIdForDelete, setZoneIdForDelete] = useState(null);
  const [parkIdForDelete, setParkIdForDelete] = useState(null);
  const [pointIdForDelete, setPointIdForDelete] = useState(null);
  const [loading, setLoading] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [updatingStatus, setUpdatingStatus] = useState(false);

  const classes = useStyles();

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    setPointsInZone(panel.WayPoints);
    onZoneSelected(panel);
  };

  const handleConfirmDialogOpen = (zoneId, parkId, wpId = null) => {
    setZoneIdForDelete(zoneId);
    setParkIdForDelete(parkId);
    setPointIdForDelete(wpId);

    if (wpId !== null) {
      setShowConfirmationForPoint(true);
    } else {
      setShowConfirmation(true);
    }
  };

  const handleZoneStatusToggled = (e, zone) => {
    e.stopPropagation();
    setUpdatingStatus(true);

    updateZoneStatus(
      authContext,
      zone.ParkId,
      zone.ZoneId,
      e.target.checked
    ).then(_msg => {
      let locationsCopy = locationsInPark;
      const updatedZone = locationsCopy.find(z => z.ZoneId === zone.ZoneId);
      const indexForZone = locationsCopy.indexOf(updatedZone);

      updatedZone.IsActive = !updatedZone.IsActive;
      locationsCopy.splice(indexForZone, 1, updatedZone);

      setLocationsInPark(locationsCopy);
      setUpdatingStatus(false);
    });
  };

  const handleConfirmDialogClose = () => {
    setShowConfirmation(false);
    setShowConfirmationForPoint(false);
  };

  const handleZoneDelete = () => {
    deleteZone(authContext, parkIdForDelete, zoneIdForDelete).then(_data => {
      setShowConfirmation(false);
      let copy = [...locationsInPark];
      let filtered = copy.filter(item => item.ZoneId !== zoneIdForDelete);
      setLocationsInPark(filtered);
    });
  };

  const handlePointDelete = () => {
    const payload = {
      ZoneId: zoneIdForDelete,
      Id: pointIdForDelete,
    };

    let locationsCopy = locationsInPark;

    const zone = locationsInPark.find(zone => zone.ZoneId === zoneIdForDelete);
    const indexForZone = locationsInPark.indexOf(zone);

    deletePoint(authContext, payload, parkIdForDelete).then(_data => {
      setShowConfirmationForPoint(false);
      let copy = [...zone.WayPoints];
      let filtered = copy.filter(item => item.Id !== pointIdForDelete);
      zone.WayPoints = filtered;
      locationsCopy.splice(indexForZone, 1, zone);
      setLocationsInPark(locationsCopy);
      setPointsInZone(zone.WayPoints);
    });
  };

  const ZoneAccordion = ({ locations }) => {
    return locations.map(location => (
      <Accordion
        key={location.ZoneId}
        expanded={expanded === location}
        onChange={handleChange(location)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='zone-content'
          id='zone-header'
        >
          <Grid container spacing={1} className={classes.grid}>
            <Grid item xs={1}>
              <Avatar style={{ backgroundColor: location.Color }}>
                &nbsp;
              </Avatar>
            </Grid>
            <Grid item xs={4}>
              <Typography className={classes.heading}>
                {location.ShortName}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography className={classes.secondaryHeading}>
                {location.Description}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              {updatingStatus ? (
                <CircularProgress />
              ) : (
                <FormControlLabel
                  onClick={event => event.stopPropagation()}
                  onFocus={event => event.stopPropagation()}
                  control={
                    <Switch
                      color='primary'
                      checked={location.IsActive}
                      value={location.IsActive}
                      onChange={e => handleZoneStatusToggled(e, location)}
                    />
                  }
                  label={
                    <Typography className={classes.secondaryHeading}>
                      Active
                    </Typography>
                  }
                  labelPlacement='start'
                />
              )}
            </Grid>
          </Grid>
        </AccordionSummary>

        <AccordionActions
          style={{ justifyContent: 'flex-start', marginLeft: '10px' }}
        >
          <Button
            size='small'
            color='primary'
            startIcon={<EditIcon />}
            onClick={() => navigate(`/explorer-quest/zone/${location.ZoneId}`)}
          >
            Edit Zone
          </Button>
          <Button
            size='small'
            startIcon={<DeleteIcon />}
            onClick={() =>
              handleConfirmDialogOpen(location.ZoneId, location.ParkId)
            }
          >
            Delete Zone
          </Button>
        </AccordionActions>

        {/* WayPoints */}
        <AccordionDetails>
          <WaypointList
            zone={location}
            points={location.WayPoints}
            confirmDeletePoint={handleConfirmDialogOpen}
          />
        </AccordionDetails>
      </Accordion>
    ));
  };

  useEffect(() => {
    setShowConfirmation(false);
    setLoading(true);

    if (authContext.tbProps.selectedPark) {
      const selectedParkId = authContext.tbProps.selectedPark.ParkId;

      setCreateButtonDisabled(false);

      getZones(authContext, selectedParkId).then(data => {
        const zoneData = data.map(z => {
          return new Zone(
            z.ZoneId,
            z.ShortName,
            z.LongName,
            z.Description,
            z.Latitude,
            z.Longitude,
            z.Radius,
            z.Color,
            z.ParkId,
            z.IsActive,
            []
          );
        });

        const zones = zoneData.sort((a, b) => a.ZoneId - b.ZoneId);
        setLocationsInPark(zones);

        zones.forEach(zone => {
          getPoints(authContext, zone).then(data => {
            if (typeof data === 'undefined') {
              return;
            } else {
              const wayPoints = data.map(p => {
                return new Point(
                  p.Id,
                  p.ZoneId,
                  p.ShortName,
                  p.LongName,
                  p.Description,
                  p.Latitude,
                  p.Longitude,
                  p.Radius,
                  p.Color,
                  p.Proximity,
                  p.ImageId,
                  p.Image,
                  p.IsHidden,
                  p.IsActive,
                  p.Activity
                );
              });

              zone.WayPoints = wayPoints;
            }
          });
        });

        setLoading(false);
      });
    }
  }, [authContext.tbProps.selectedPark]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Button
        variant='contained'
        color='primary'
        disabled={createButtonDisabled}
        onClick={() => navigate('/explorer-quest/zone/new')}
        style={{ marginBottom: '10px' }}
      >
        Create a New Zone
      </Button>

      {loading ? (
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress />
        </Backdrop>
      ) : locationsInPark.length === 0 ? (
        <NoLocationsText>
          No locations created for Explorer Quest.
        </NoLocationsText>
      ) : (
        <ZoneAccordion locations={locationsInPark} />
      )}

      <ConfirmationDialog
        open={showConfirmation}
        close={handleConfirmDialogClose}
        confirm={handleZoneDelete}
        warning={true}
      />

      <ConfirmationDialog
        open={showConfirmationForPoint}
        close={handleConfirmDialogClose}
        confirm={handlePointDelete}
        warning={false}
      />
    </>
  );
}

export default ZoneList;
