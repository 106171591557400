import { useState, useEffect, useContext } from 'react';
import { navigate } from 'hookrouter';
import { Row, Col, Container, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { UserContext } from 'context/UserContext';
import Dropdown from '../Dropdown';
import { getLostTrackers } from '../../services/TrackersService';
import { getParks } from '../../services/Parks.Service';
import { parkTitle } from '../../helpers/DropdownTitle';

export default function LostChild({ ...props }) {
  const { state, dispatch } = useContext(UserContext);
  const [parks, setParks] = useState([]);
  const [selectedPark, setSelectedPark] = useState(null);
  const [lostTrackers, setLostTrackers] = useState([]);

  const togglePark = park => {
    setSelectedPark(park);
    getLostTrackers(park, setLostTrackers);
  };

  useEffect(() => {
    // Check for Authorization
    if (state.userRoles.indexOf(1) === -1) {
      navigate('/not-authorized');
    }

    getParks(setParks);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let timer = null;
    if (selectedPark) {
      timer = setInterval(() => {
        getLostTrackers(selectedPark.ParkId, setLostTrackers);
      }, 1000);
    } else {
      clearInterval(timer);
      setLostTrackers([]);
    }
    return () => clearInterval(timer);
  }, [selectedPark]);

  const viewTracker = tracker => {};

  return (
    <div className='App'>
      <Container fluid>
        <Row>
          <Col>
            <div className='header-row'>Lost Child View</div>
          </Col>
        </Row>
        <Row>
          <Col xs={2} md={1}>
            Park:
          </Col>
          <Col xs={4} md={3}>
            <Dropdown
              items={parks}
              toggleItem={setSelectedPark}
              titleCreator={parkTitle}
              initialTitle={'Select Park'}
              //listOpenCallback={setParkListOpen}
            />
          </Col>
          <Col xs={12} md={8}></Col>
        </Row>
        <div>
          <Row className='header-row'>
            <Col>Nickname</Col>
            <Col>Gender</Col>
            <Col>Special Needs</Col>
            <Col>Battery Status</Col>
            <Col></Col>
          </Row>
        </div>
        {lostTrackers.map((item, index) => (
          <Row key={index} className='list-row'>
            <Col>{item.Nickname}</Col>
            <Col>{item.Gender}</Col>
            <Col>{item.SpecialNeeds}</Col>
            <Col>{item.BatteryStatus}</Col>
            <Col>
              <Link
                to={{
                  pathname: '/TrackerView/Lost',
                  state: {
                    trackerId: item.TrackerId,
                    accountId: item.AccountId,
                    fromLost: true,
                  },
                }}
              >
                <Button variant='primary' onClick={() => viewTracker(item)}>
                  View
                </Button>
              </Link>
            </Col>
          </Row>
        ))}
      </Container>
    </div>
  );
}
