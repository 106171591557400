export function convertGender(gender: number) {
  switch (gender) {
    case 1:
      return 'F';
    case 2:
      return 'M';
    case 3:
      return '';
    default:
      return '';
  }
}

export function convertStatus(status: number) {
  switch (status) {
    case 1:
      return 'Initialized';
    case 2:
      return 'Lost';
    case 3:
      return 'Accepted';
    case 4:
      return 'Located';
    case 5:
      return 'Returned';
    case 6:
      return 'UnknownLost';
    default:
      return 'UnknownStatus';
  }
}

export enum ImageType {
  DevApp = 1,
  Child,
  User,
  Scavenger,
  ChallengeComplete,
  TutorialMain,
  TutorialCompass,
  ParkMap,
  Badge,
  Faq,
}

export const USERROLES = {
  TraKidAdmin: 1,
  TraKidCs: 2,
  OrgAdmin: 3,
  ParkAdmin: 4,
  SecurityAdmin: 5,
  StaffApp: 6,
  Dispatch: 7,
  TrackerView: 8,
  DevTools: 9,
  Devices: 10,
  Reports: 11,
  EditScavenger: 12,
  ToggleScavenger: 13,
  ViewScavenger: 14,
  Parent: 15,
  InitialAccount: 16,
  Guardian: 17,
};
