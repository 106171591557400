import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'hookrouter';

import AuthContext from 'context/Auth/auth';
import SuccessButton from 'components/common/buttons/SuccessButton';
import { ToolCardForm } from '../ToolCardForm';
import { ToolCardMultiForm } from '../TooCardMultiForm';

import { notifyAllAccounts } from 'services/NotificationsService';
import {
  sendUdpMessage,
  sendTestEmail,
  sendUdpMessageToTracker,
} from 'services/Tools.Service';

import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Card,
  CardActions,
  CardContent,
  Input,
  InputLabel,
  FormControl,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexGrow: 1,
  },
  toolCard: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    padding: '10px',
  },
  title: {
    fontSize: 18,
    textAlign: 'center',
  },
}));

function MessageTools({ triggerSnack, setMessage, setSeverity }) {
  const context = useContext(AuthContext);
  const classes = useStyles();

  // UDP MESSAGE
  const [ipAddressInput, setIpAddressInput] = useState('');
  const [portInput, setPortInput] = useState('');
  const [payloadInput, setPayloadInput] = useState('');
  const [disableSendUdpBtn, setDisableSendUdpBtn] = useState(true);

  // UDP MESSAGE W/TRACKER ID
  const [trackerIdInput, setTrackerIdInput] = useState('');
  const [payloadInputForTracker, setPayloadInputForTracker] = useState('');
  const [disableSendUdpTrackerBtn, setDisableSendUdpTrackerBtn] =
    useState(true);

  // TEST EMAIL
  const [emailAddressInput, setEmailAddressInput] = useState('');
  const [disableSendEmailBtn, setDisableSendEmailBtn] = useState(true);

  // TEST NOTIFICATION
  const [notificationMessageInput, setNotificationMessageInput] = useState('');

  //#region Effects
  useEffect(() => {
    if (!context.token) {
      navigate('/logout', true);
    }
  }, [context.token]);

  useEffect(() => {
    ipAddressInput !== '' && portInput !== '' && payloadInput !== ''
      ? setDisableSendUdpBtn(false)
      : setDisableSendUdpBtn(true);
  }, [ipAddressInput, portInput, payloadInput]);

  useEffect(() => {
    trackerIdInput !== '' && payloadInputForTracker !== ''
      ? setDisableSendUdpTrackerBtn(false)
      : setDisableSendUdpTrackerBtn(true);
  }, [trackerIdInput, payloadInputForTracker]);

  useEffect(() => {
    emailAddressInput !== ''
      ? setDisableSendEmailBtn(false)
      : setDisableSendEmailBtn(true);
  }, [emailAddressInput]);
  //#endregion

  //#region Tool Functions
  const sendMessage = () => {
    sendUdpMessage(ipAddressInput, portInput, payloadInput, context, data => {
      alert(data.Message);
    });
  };

  const sendMessageWithTrackerId = () => {
    sendUdpMessageToTracker(
      context,
      trackerIdInput,
      payloadInputForTracker,
      data => {
        alert(data.Message);
      }
    );
  };

  const sendEmail = () => {
    sendTestEmail(emailAddressInput, context, data => {
      alert(data.Message);
    });
  };

  const sendNotification = () => {
    notifyAllAccounts(
      context.tbProps.selectedPark.ParkId,
      notificationMessageInput,
      context,
      data => {
        alert(data.Message);
      }
    );
  };
  //#endregion

  return (
    <>
      <Grid container spacing={2} alignItems='stretch'>
        {/* SEND UDP MESSAGE */}
        <Grid item xs={12} md={6} lg={3}>
          <Card className={classes.toolCard}>
            <CardContent>
              <ToolCardMultiForm
                actionLabel='Send UDP Message (IP Address)'
                disableForm={false}
              >
                <FormControl>
                  <InputLabel htmlFor='ipAddressInput'>IP Address</InputLabel>
                  <Input
                    id='ipAddressInput'
                    value={ipAddressInput}
                    onChange={e => setIpAddressInput(e.target.value)}
                  />
                </FormControl>

                <FormControl>
                  <InputLabel htmlFor='portInput'>Port</InputLabel>
                  <Input
                    id='portInput'
                    value={portInput}
                    onChange={e => setPortInput(e.target.value)}
                  />
                </FormControl>

                <FormControl>
                  <InputLabel htmlFor='payloadInput'>Payload</InputLabel>
                  <Input
                    id='payloadInput'
                    value={payloadInput}
                    onChange={e => setPayloadInput(e.target.value)}
                  />
                </FormControl>
              </ToolCardMultiForm>
            </CardContent>
            <CardActions>
              <SuccessButton onClick={sendMessage} disabled={disableSendUdpBtn}>
                Send
              </SuccessButton>
            </CardActions>
          </Card>
        </Grid>

        {/* SEND UDP MESSAGE W/TRACKER ID */}
        <Grid item xs={12} md={6} lg={3}>
          <Card className={classes.toolCard}>
            <CardContent>
              <ToolCardMultiForm
                actionLabel='Send UDP Message (Tracker ID)'
                disableForm={false}
              >
                <FormControl>
                  <InputLabel htmlFor='trackerIdInput'>Tracker ID</InputLabel>
                  <Input
                    id='trackerIdInput'
                    value={trackerIdInput}
                    onChange={e => setTrackerIdInput(e.target.value)}
                  />
                </FormControl>

                <FormControl>
                  <InputLabel htmlFor='payloadInputForTracker'>
                    Payload
                  </InputLabel>
                  <Input
                    id='payloadInputForTracker'
                    value={payloadInputForTracker}
                    onChange={e => setPayloadInputForTracker(e.target.value)}
                  />
                </FormControl>
              </ToolCardMultiForm>
            </CardContent>
            <CardActions>
              <SuccessButton
                onClick={sendMessageWithTrackerId}
                disabled={disableSendUdpTrackerBtn}
              >
                Send
              </SuccessButton>
            </CardActions>
          </Card>
        </Grid>

        {/* SEND TEST EMAIL */}
        <Grid item xs={12} md={6} lg={3}>
          <Card className={classes.toolCard}>
            <CardContent>
              <ToolCardForm actionLabel='Send Test Email' disableForm={false}>
                <InputLabel htmlFor='emailAddressInput'>
                  Email Address
                </InputLabel>
                <Input
                  id='emailAddressInput'
                  value={emailAddressInput}
                  onChange={e => setEmailAddressInput(e.target.value)}
                />
              </ToolCardForm>
            </CardContent>
            <CardActions>
              <SuccessButton onClick={sendEmail} disabled={disableSendEmailBtn}>
                Send
              </SuccessButton>
            </CardActions>
          </Card>
        </Grid>

        {/* SEND TEST NOTIFICATION */}
        <Grid item xs={12} md={6} lg={3}>
          <Card className={classes.toolCard}>
            <CardContent>
              <ToolCardForm
                actionLabel='Send Test Notification'
                disableForm={false}
              >
                <InputLabel htmlFor='notificationMessageInput'>
                  Message
                </InputLabel>
                <Input
                  id='notificationMessageInput'
                  value={notificationMessageInput}
                  onChange={e => setNotificationMessageInput(e.target.value)}
                />
              </ToolCardForm>
            </CardContent>
            <CardActions>
              <SuccessButton
                onClick={sendNotification}
                disabled={!context.tbProps.selectedPark}
              >
                Send
              </SuccessButton>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

MessageTools.propTypes = {
  triggerSnack: PropTypes.func.isRequired,
  setMessage: PropTypes.func.isRequired,
  setSeverity: PropTypes.func.isRequired,
};

export default MessageTools;
