import { getBaseUrl, getAPIVersion } from '../config';

const version = getAPIVersion();
const path = `/api/${version}/notifications/`;

export function notifyAllAccounts(parkId, message, context, callback) {
  fetch(getBaseUrl() + path + 'notifyallaccounts', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + context.token,
    },
    body: JSON.stringify({
      parkId: parkId,
      message: message,
    }),
  })
    .then(res => res.json())
    .then(data => {
      callback(data);
    })
    .catch(console.log);
}
