import { ChangeEvent } from 'react';

import { Tutorial } from 'models/Tutorial';

import { Grid, TextField } from '@material-ui/core';

interface Props {
  tutorial: Tutorial;
  onFormFieldUpdate: (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
}
const SpotScreenForm = ({ tutorial, onFormFieldUpdate }: Props) => {
  return (
    <form>
      <Grid
        container
        spacing={2}
        direction='column'
        justify='center'
        alignItems='stretch'
      >
        <Grid item>
          <TextField
            id='title-text-2'
            label={`Title for ${
              tutorial.StateText2 === '' ? 'Spot' : tutorial.StateText2
            } Screen`}
            onChange={onFormFieldUpdate}
            name='Title2'
            value={tutorial.Title2}
            fullWidth
            variant='outlined'
          />
        </Grid>

        <Grid item>
          <TextField
            id='description-text-2'
            label={`Description for ${
              tutorial.StateText2 === '' ? 'Spot' : tutorial.StateText2
            } Screen`}
            onChange={onFormFieldUpdate}
            name='Description2'
            value={tutorial.Description2}
            fullWidth
            variant='outlined'
            multiline
            rows={4}
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default SpotScreenForm;
