import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { getTrackerEntries } from '../services/TrackersService';

function CoordinateFeed({
  coordinateCount,
  selectedChild,
  selectedAccount,
  setMarkersCallback,
  context,
}) {
  const [trackerEntries, setTrackerEntries] = useState([]);
  const [title, setTitle] = useState('GPS Feed'); // eslint-disable-line no-unused-vars

  useEffect(() => {
    let timer = null;
    if (selectedChild && selectedChild.TrackerId) {
      timer = setInterval(() => {
        getTrackerEntries(
          selectedChild.TrackerId,
          selectedAccount.AccountId,
          coordinateCount,
          context,
          updateTrackerEntries
        );
      }, 1000);
    } else {
      clearInterval(timer);
      setTrackerEntries([]);
    }
    return () => clearInterval(timer);
  }, [selectedChild?.TrackerId]); // eslint-disable-line react-hooks/exhaustive-deps

  function updateTrackerEntries(data) {
    setTrackerEntries(data);
    setMarkersCallback(data);
  }

  return (
    <div>
      <div>
        <div>{title}</div>
        <Row>
          <Col>Timestamp</Col>
          <Col>Latitude</Col>
          <Col>Longitude</Col>
        </Row>
      </div>
      {trackerEntries?.map((item, index) => (
        <Row key={index}>
          <Col>{item.Timestamp}</Col>
          <Col>
            {item.Latitude.toString().substring(
              0,
              item.Latitude.toString().indexOf('.') + 7
            )}
          </Col>
          <Col>
            {item.Longitude.toString().substring(
              0,
              item.Longitude.toString().indexOf('.') + 7
            )}
          </Col>
        </Row>
      ))}
    </div>
  );
}

export default CoordinateFeed;
