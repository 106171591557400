import { useState, useEffect, useContext } from 'react';
import { navigate } from 'hookrouter';
import { Row, Col, Container } from 'react-bootstrap';
import { useCookies } from 'react-cookie';

import Dropdown from '../../Dropdown';
import CoordinateFeed from '../../CoordinateFeed';
import Map from '../../Map';
import AuthContext from '../../../context/Auth/auth';
import { UserContext } from 'context/UserContext';
import { hasAuthorization } from 'helpers/HasAuthorization';

import { getAccounts } from '../../../services/AccountsService';
import { getChildren } from '../../../services/ChildrenService';
import {
  parkTitle,
  accountTitle,
  trackerTitle,
} from '../../../helpers/DropdownTitle';

export default function TrackerView({ ...props }) {
  const coordinateCount = 20;
  const [selectedPark, setSelectedPark] = useState(null);
  const [accounts, setAccounts] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [children, setChildren] = useState([]);
  const [selectedChild, setSelectedChild] = useState(null);
  const [parkListOpen, setParkListOpen] = useState(false);
  const [accountListOpen, setAccountListOpen] = useState(false);
  const [markers, setMarkers] = useState([]);
  const [mapCenter, setMapCenter] = useState({});
  const [cookies, setCookie] = useCookies(['selectedOrg', 'selectedPark']);

  const context = useContext(AuthContext);
  const { state, dispatch } = useContext(UserContext);

  useEffect(() => {
    if (!context.token) {
      navigate('/logout', true);
    }
  }, [context.token]);

  useEffect(() => {
    if (selectedPark) {
      getAccounts(context, setAccounts);
      setSelectedAccount(null);
      setMapCenter({ lat: selectedPark.Latitude, lng: selectedPark.Longitude });
    }
  }, [selectedPark]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (selectedAccount) {
      getChildren(selectedAccount.AccountId, context, setChildren);
    }
  }, [selectedAccount]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (context.tbProps.selectedPark) {
      console.log('changed asdf', context.tbProps.selectedPark);
      setSelectedPark(context.tbProps.selectedPark);
    }
  }, [context.tbProps.selectedPark]);

  useEffect(() => {
    // Check for Authorization
    if (state.orgId !== null) {
      if (!hasAuthorization(state.userRoles, [1])) {
        navigate('/not-authorized');
      }
    }

    getAccounts(context, setAccounts);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container fluid>
      <Row>
        <Col>
          <div className='header-row'>Tracker View</div>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={8}>
          <div className='map-row'>
            <Map
              markers={markers}
              selectedAccount={selectedAccount}
              selectedChild={selectedChild}
              mapCenter={mapCenter}
            />
          </div>
        </Col>
        <Col>
          <Row>
            <Col xs={4} md={3}>
              Account:
            </Col>
            <Col xs={14} md={9}>
              <Dropdown
                items={accounts}
                toggleItem={setSelectedAccount}
                titleCreator={accountTitle}
                initialTitle={'Select Account'}
                listOpenCallback={setAccountListOpen}
                closeDropdown={parkListOpen}
                disabled={selectedPark === null}
              />
            </Col>
          </Row>

          <Row>
            <Col xs={4} md={3}>
              Child:
            </Col>
            <Col xs={14} md={9}>
              <Dropdown
                items={children}
                toggleItem={setSelectedChild}
                titleCreator={trackerTitle}
                initialTitle={'Select Child'}
                closeDropdown={parkListOpen || accountListOpen}
                disabled={selectedAccount === null}
              />
            </Col>
          </Row>

          <Row>
            <Col>
              <CoordinateFeed
                coordinateCount={coordinateCount}
                selectedChild={selectedChild}
                selectedAccount={selectedAccount}
                setMarkersCallback={marks => {
                  setMarkers(marks);
                }}
                context={context}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}
