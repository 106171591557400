import React, { FunctionComponent } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: 16,
    fontWeight: 900,
    textAlign: 'left',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

type ToolCardFormProps = {
  actionLabel: string,
}

export const ToolCardForm: FunctionComponent<ToolCardFormProps> = (props) => {
  const classes = useStyles();

  return (
    <>
      <Typography
        className={classes.title}
        color='textSecondary'
        gutterBottom
      >
        {props.actionLabel}
      </Typography>
      <form className={classes.form}>
        {props.children}
      </form>
    </>
  );
}