import { ChangeEvent } from 'react';

import { Tutorial } from 'models/Tutorial';

import { Grid, TextField, Button } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

interface Props {
  tutorial: Tutorial;
  onFormFieldUpdate: (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
  onParkImageUpdate: (e: ChangeEvent<HTMLInputElement>) => void;
}
const WelcomeScreenOneForm = ({
  tutorial,
  onFormFieldUpdate,
  onParkImageUpdate,
}: Props) => (
  <form>
    <Grid
      container
      spacing={2}
      direction='column'
      justify='center'
      alignItems='stretch'
    >
      <Grid item>
        <Button
          variant='contained'
          color='default'
          component='label'
          startIcon={<CloudUploadIcon />}
        >
          Select Image
          <input
            type='file'
            accept='.jpeg, .png, .jpg'
            hidden
            onChange={e => onParkImageUpdate(e)}
          />
        </Button>
      </Grid>

      <Grid item>
        <TextField
          id='game-name'
          label='Explorer Quest Name'
          onChange={onFormFieldUpdate}
          name='ScavengerHuntName'
          value={tutorial.ScavengerHuntName}
          fullWidth
          variant='outlined'
        />
      </Grid>
    </Grid>
  </form>
);

export default WelcomeScreenOneForm;
