import { getBaseUrl, getAPIVersion } from '../config';

const version = getAPIVersion();
const path = `/api/${version}/children/`;

export function getChildren(accountId, context, callback) {
  fetch(getBaseUrl() + path + 'getchildren', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + context.token,
    },
    body: JSON.stringify({
      accountId: accountId,
    }),
  })
    .then(res => {
      if (res.status === 401) {
        context.logout();
      } else {
        return res.json();
      }
    })
    .then(data => {
      callback(data);
    })
    .catch(console.log);
}

export function getLostChildren(parkId, context, callback) {
  fetch(getBaseUrl() + path + 'getlostchildren', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + context.token,
    },
    body: JSON.stringify({
      parkId: parkId,
    }),
  })
    .then(res => {
      if (res.status === 401) {
        context.logout();
      } else {
        return res.json();
      }
    })
    .then(data => {
      callback(data);
    })
    .catch(console.log);
}
