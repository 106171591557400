import React, {
  useState,
  useEffect,
  useContext,
  ChangeEvent,
  SyntheticEvent,
} from 'react';
import imageCompression from 'browser-image-compression';
import { AxiosResponse } from 'axios';

import AuthContext from 'context/Auth/auth';
import { ImageType } from 'helpers/EnumHelper';

import { Tutorial, TutorialStarter } from 'models/Tutorial';
import { getTutorial, updateTutorial } from 'services/Parks.Service';
import {
  getImage,
  setParkImage,
  setCompassImage,
} from 'services/Image.Service';
import Alert from 'components/common/Alert';

// Forms
import WelcomeScreenOneForm from '../cms/forms/WelcomeScreenOneForm';
import WelcomeScreenTwoForm from '../cms/forms/WelcomeScreenTwoForm';
import GameStatesForm from '../cms/forms/GameStatesForm';
import NavigateScreenForm from '../cms/forms/NavigateScreenForm';
import SpotScreenForm from '../cms/forms/SpotScreenForm';
import EngageScreenForm from '../cms/forms/EngageScreenForm';

// Mockups
import WelcomeScreenOnePreview from '../cms/previews/WelcomeScreenOnePreview';
import WelcomeScreenTwoPreview from '../cms/previews/WelcomeScreenTwoPreview';
import GameStatesPreview from '../cms/previews/GameStatesPreview';
import NavigatePreview from '../cms/previews/NavigatePreview';
import SpotPreview from '../cms/previews/SpotPreview';
import EngagePreview from '../cms/previews/EngagePreview';

import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Paper,
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  CircularProgress,
  Snackbar,
  SnackbarCloseReason,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  loading: {
    justifyContent: 'center',
    display: 'flex',
  },
  stepper: {
    marginBottom: '16px',
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  stepButtons: {
    float: 'right',
  },
  paper: {
    display: 'flex',
    padding: '10px',
  },
  paperForm: {
    padding: '10px',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    flexGrow: 1,
    height: '700px',

    '& > *': {
      boxSizing: 'content-box',
      width: '320px',
    },
  },
}));

function getSteps() {
  return [
    'Welcome Screen',
    'Welcome Screen 2',
    'Game States Screen',
    'Navigate Screen',
    'Spot Screen',
    'Help Screen',
  ];
}

function getStepContent(stepIndex: number) {
  switch (stepIndex) {
    case 0:
      return '';
    case 1:
      return '';
    case 2:
      return '';
    case 3:
      return '';
    case 4:
      return '';
    case 5:
      return '';
    default:
      return 'Unknown stepIndex';
  }
}

interface Props {
  parkId: number;
  resetTab: React.Dispatch<React.SetStateAction<number>>;
}
function SetupTab({ parkId, resetTab }: Props) {
  const authContext = useContext(AuthContext);
  const classes = useStyles();

  const [openSnack, setOpenSnack] = useState(false);
  const [snackSeverity, setSnackSeverity] = useState('success');
  const [snackMessage, setSnackMessage] = useState('');

  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  const [loading, setLoading] = useState(false);
  const [imagesLoading, setImagesLoading] = useState(false);

  const [tutorial, setTutorial] = useState<Tutorial>(TutorialStarter());
  const [mainImage, setMainImage] = useState<string | null>(null);
  const [tutorialCompassImage, setTutorialCompassImage] = useState<
    string | null
  >(null);

  const handleNext = () => {
    if (activeStep === 5) {
      handleTutorialSubmit();
      // TODO form validation
      // TODO disable buttons
    } else {
      setActiveStep(prevActiveStep => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleClose = (
    event: SyntheticEvent<any, Event>,
    reason: SnackbarCloseReason
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnack(false);
  };

  const handleFormUpdate = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const { name, value } = event.target;

    setTutorial({ ...tutorial, [name]: value });
  };

  const handleTutorialSubmit = () => {
    console.log('tutorial: ', tutorial);
    updateTutorial(authContext, parkId, tutorial).then(response => {
      console.log('Response: ', response);

      setSnackSeverity('success');
      setSnackMessage(response.data.Message);
      setOpenSnack(true);

      setTimeout(() => {
        resetTab(0);
      }, 1000);
    });
  };

  async function handleParkImageUpdate(event: ChangeEvent<HTMLInputElement>) {
    let imageFile: File;

    if (event.target.files !== null) {
      imageFile = event.target.files[0];

      const options = {
        maxSize: 0.5,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };

      try {
        const compressedFile = await imageCompression(imageFile, options);

        await imageCompression
          .getDataUrlFromFile(compressedFile)
          .then((value: string) => {
            setLoading(true);

            setParkImage(authContext, parkId, value)
              .then(response => {
                setMainImage(value);

                setSnackSeverity('success');
                setSnackMessage(response.data.Message);
                setOpenSnack(true);

                setLoading(false);
              })
              .catch(error => {
                console.error(error);

                setSnackSeverity('error');
                setSnackMessage('An error occurred');
                setOpenSnack(true);

                setLoading(false);
              });
          });
        // .then((resolve: string, reject) => {
        //   if (!!!reject) {
        //     setLoading(true);

        //     setParkImage(authContext, parkId, resolve)
        //       .then(response => {
        //         setMainImage(resolve);

        //         setSnackSeverity('success');
        //         setSnackMessage(response.data.Message);
        //         setOpenSnack(true);

        //         setLoading(false);
        //       })
        //       .catch(error => {
        //         console.error(error);

        //         setSnackSeverity('error');
        //         setSnackMessage('An error occurred');
        //         setOpenSnack(true);

        //         setLoading(false);
        //       });
        //   }
        // });
      } catch (error) {
        console.error(error);
      }
    }
  }

  async function handleTutorialCompassImageUpdate(
    event: ChangeEvent<HTMLInputElement>
  ) {
    let imageFile: File;

    if (event.target.files !== null) {
      imageFile = event.target.files[0];

      const options = {
        maxSize: 0.5,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };

      try {
        const compressedFile = await imageCompression(imageFile, options);

        await imageCompression
          .getDataUrlFromFile(compressedFile)
          .then((resolve: string) => {
            setLoading(true);

            setCompassImage(authContext, parkId, resolve)
              .then(response => {
                console.log(response);
                setTutorialCompassImage(resolve);
                setLoading(false);
              })
              .catch(error => {
                console.error(error);
                setLoading(false);
              });
          });
        // .then((resolve, reject) => {
        //   if (!!!reject) {
        //     setLoading(true);

        //     setCompassImage(authContext, parkId, resolve)
        //       .then(response => {
        //         console.log(response);
        //         setTutorialCompassImage(resolve);
        //         setLoading(false);
        //       })
        //       .catch(error => {
        //         console.error(error);
        //         setLoading(false);
        //       });
        //   }
        // });
      } catch (error) {
        console.error(error);
      }
    }
  }

  useEffect(() => {
    setLoading(true);
    setImagesLoading(true);

    getTutorial(authContext, parkId).then((res: AxiosResponse<any>) => {
      const fetchedTutorial: Tutorial = res.data;

      if (fetchedTutorial.Id === -1) {
        // Create new Tutorial object.
        const parkTutorial = TutorialStarter(parkId);

        setTutorial(parkTutorial);

        setTimeout(() => {
          setLoading(false);
        }, 1000);
      } else {
        setTutorial(fetchedTutorial);

        if (fetchedTutorial.TutorialMainImageId) {
          getImage(
            authContext,
            fetchedTutorial.TutorialMainImageId,
            ImageType.TutorialMain
          ).then(response => {
            setMainImage(response.data.Image);
            setImagesLoading(false);
          });
        }

        if (fetchedTutorial.TutorialCompassImageId) {
          getImage(
            authContext,
            fetchedTutorial.TutorialCompassImageId,
            ImageType.TutorialCompass
          ).then(response => {
            setTutorialCompassImage(response.data.Image);
          });
        }

        setTimeout(() => {
          setLoading(false);
        }, 1000);
      }
    });
  }, [authContext]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={classes.root}>
      {loading ? (
        <div className={classes.loading}>
          <CircularProgress />
        </div>
      ) : (
        <>
          <Stepper activeStep={activeStep} className={classes.stepper}>
            {steps.map(label => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>

          <Grid container spacing={3}>
            {/* WELCOME (1) */}
            {activeStep === 0 ? (
              <>
                <Grid item xs={12} md={6} lg={8}>
                  <Paper className={classes.paperForm}>
                    <WelcomeScreenOneForm
                      tutorial={tutorial}
                      onFormFieldUpdate={handleFormUpdate}
                      onParkImageUpdate={handleParkImageUpdate}
                    />
                  </Paper>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <Paper className={classes.paper}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <WelcomeScreenOnePreview
                          tutorial={tutorial}
                          parkImage={mainImage}
                          loading={imagesLoading}
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </>
            ) : null}

            {/* WELCOME (2) */}
            {activeStep === 1 && (
              <>
                <Grid item xs={12} md={6} lg={8}>
                  <Paper className={classes.paperForm}>
                    <WelcomeScreenTwoForm
                      tutorial={tutorial}
                      onFormFieldUpdate={handleFormUpdate}
                    />
                  </Paper>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <Paper className={classes.paper}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <WelcomeScreenTwoPreview
                          tutorial={tutorial}
                          parkImage={mainImage}
                          loading={imagesLoading}
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </>
            )}

            {/* GAME STATES */}
            {activeStep === 2 ? (
              <>
                <Grid item xs={12} md={6} lg={8}>
                  <Paper className={classes.paperForm}>
                    <GameStatesForm
                      tutorial={tutorial}
                      onFormFieldUpdate={handleFormUpdate}
                    />
                  </Paper>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <Paper className={classes.paper}>
                    <GameStatesPreview tutorial={tutorial} />
                  </Paper>
                </Grid>
              </>
            ) : null}

            {/* NAVIGATE */}
            {activeStep === 3 ? (
              <>
                <Grid item xs={12} md={6} lg={8}>
                  <Paper className={classes.paperForm}>
                    <NavigateScreenForm
                      tutorial={tutorial}
                      onFormFieldUpdate={handleFormUpdate}
                    />
                  </Paper>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <Paper className={classes.paper}>
                    <NavigatePreview tutorial={tutorial} />
                  </Paper>
                </Grid>
              </>
            ) : null}

            {/* SPOT */}
            {activeStep === 4 ? (
              <>
                <Grid item xs={12} md={6} lg={8}>
                  <Paper className={classes.paperForm}>
                    <SpotScreenForm
                      tutorial={tutorial}
                      onFormFieldUpdate={handleFormUpdate}
                    />
                  </Paper>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <Paper className={classes.paper}>
                    <SpotPreview tutorial={tutorial} />
                  </Paper>
                </Grid>
              </>
            ) : null}

            {/* ENGAGE */}
            {activeStep === 5 ? (
              <>
                <Grid item xs={12} md={6} lg={8}>
                  <Paper className={classes.paperForm}>
                    <EngageScreenForm
                      tutorial={tutorial}
                      onFormFieldUpdate={handleFormUpdate}
                      onCompassImageUpdate={handleTutorialCompassImageUpdate}
                    />
                  </Paper>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <Paper className={classes.paper}>
                    <EngagePreview
                      tutorial={tutorial}
                      compassImage={tutorialCompassImage}
                    />
                  </Paper>
                </Grid>
              </>
            ) : null}
          </Grid>

          <div className={classes.stepButtons}>
            {activeStep === steps.length ? (
              <div>
                <Typography className={classes.instructions}>
                  All steps completed
                </Typography>
              </div>
            ) : (
              <div>
                <Typography className={classes.instructions}>
                  {getStepContent(activeStep)}
                </Typography>
                <div>
                  <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    className={classes.backButton}
                  >
                    Back
                  </Button>
                  <Button
                    disabled={loading}
                    variant='contained'
                    color='primary'
                    onClick={handleNext}
                  >
                    {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                  </Button>
                </div>
              </div>
            )}
          </div>
        </>
      )}

      {/* TODO */}
      <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={snackSeverity}>
          {snackMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default SetupTab;
