import React from 'react';
import { Row, Col } from 'react-bootstrap';

export const orgTitle = org => (
  <Row className='stretch-row'>
    <Col>{org.Name}</Col>
  </Row>
);

export const parkTitle = park => (
  <Row className='stretch-row'>
    <Col>{park.Name}</Col>
  </Row>
);

export const accountTitle = account => (
  <Row className='stretch-row'>
    <Col xs={9} md={6}>
      {account.FirstName + ' ' + account.LastName + ': '}
    </Col>
    <Col xs={9} md={6}>
      {account.PhoneNumber}
    </Col>
  </Row>
);

export const trackerTitle = tracker => (
  <Row className='stretch-row'>
    <Col xs={9} md={6}>
      {tracker.Nickname + ': '}
    </Col>
    <Col xs={9} md={6}>
      {tracker.TrackerId}
    </Col>
  </Row>
);

export const activityTitle = activity => (
  <Row className='stretch-row'>
    <Col>{activity}</Col>
  </Row>
);
