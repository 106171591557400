import { IActivity } from 'models/IActivity';

import { Grid, TextField, FormControlLabel, Switch } from '@material-ui/core';

interface IProps {
  activity: IActivity;
  handleChange: (e: EventTarget, b: boolean) => void;
  allowAnyText: boolean;
  allowAnyTextToggled: () => void;
}
function OpenTextActivityForm(props: IProps) {
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <TextField
            id='waypoint-placeholder'
            label='Placeholder'
            name='PlaceholderText'
            helperText=''
            variant='outlined'
            value={props.activity.PlaceholderText}
            onChange={e => props.handleChange(e.target, true)}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            id='waypoint-answer'
            label='Answer'
            name='Answer'
            helperText=''
            variant='outlined'
            value={props.activity.Answer}
            onChange={e => props.handleChange(e.target, true)}
            disabled={props.allowAnyText}
          />
        </Grid>
        <Grid item xs={4}>
          <FormControlLabel
            control={
              <Switch
                checked={props.allowAnyText}
                onChange={props.allowAnyTextToggled}
                name='status'
                color='primary'
              />
            }
            label='Allow any text to be correct answer'
          />
        </Grid>
      </Grid>
    </>
  );
}

export default OpenTextActivityForm;
