import { MouseEvent } from 'react';
import { A } from 'hookrouter';

import { URLS } from 'urls';

import { makeStyles } from '@material-ui/core/styles';
import { Grid, Breadcrumbs, Typography, Link } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const useStyles = makeStyles(theme => ({
  formControl: {
    display: 'flex',
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

// function handleClick(event: MouseEvent<HTMLAnchorElement>) {
//   event.preventDefault();
//   console.info('You clicked a breadcrumb.');
// }

interface Props {}
function InfoBar(props: Props) {
  const classes = useStyles();

  return (
    <>
      <Grid container spacing={2}>
        {/* <Grid item xs={1}>
          <ArrowBackIcon />
        </Grid> */}
        <Grid item xs={11}>
          <Breadcrumbs aria-label='breadcrumb'>
            <A href={URLS.EXPLORER}>Explorer Quest</A>
            {/* <Link color='inherit' href={URLS.EXPLORER}>
          Explorer Quest
        </Link> */}
            {/* <Link
          color='inherit'
          href='/getting-started/installation/'
          onClick={handleClick}
        >
          Core
        </Link>
        <Typography color='textPrimary'>Breadcrumb</Typography> */}
          </Breadcrumbs>
        </Grid>
      </Grid>
    </>
  );
}

export default InfoBar;
