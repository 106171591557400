import axios from 'axios';
import { getBaseUrl, getAPIVersion, getSecretKey } from 'config';

const version = getAPIVersion(true);
const sk = getSecretKey();
const path = `/api/${version}/tools/`;

axios.defaults.headers.post['Content-Type'] = 'application/json';

export async function clearTrackerData(trackerId) {
  const response = await fetch(getBaseUrl() + path + 'cleartrackerdata', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      trackerId: trackerId,
      secretKey: sk,
    }),
  });

  return response.json();
}

export function clearAccountData(accountNumber, token, callback) {
  fetch(getBaseUrl() + path + 'clearaccountdata', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      phoneNumber: accountNumber,
      secretKey: sk,
    }),
  })
    .then(res => res.json())
    .then(data => {
      callback(data);
    })
    .catch(console.error);
}

export function sendUdpMessage(ipAddress, port, payload, context, callback) {
  fetch(getBaseUrl() + path + 'sendudpmessage', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + context.token,
    },
    body: JSON.stringify({
      ipAddress: ipAddress,
      port: port,
      payload: payload,
    }),
  })
    .then(res => res.json())
    .then(data => {
      callback(data);
    })
    .catch(console.log);
}

export function sendUdpMessageToTracker(context, trackerId, payload, callback) {
  fetch(getBaseUrl() + path + 'sendudpmessagetotrackerid', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + context.token,
    },
    body: JSON.stringify({
      trackerId: trackerId,
      payload: payload,
    }),
  })
    .then(res => res.json())
    .then(data => {
      callback(data);
    })
    .catch(console.log);
}

export function sendTestEmail(emailAddress, context, callback) {
  fetch(getBaseUrl() + path + 'sendtestemail', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + context.token,
    },
    body: JSON.stringify({
      email: emailAddress,
    }),
  })
    .then(res => res.json())
    .then(data => {
      callback(data);
    })
    .catch(console.log);
}

export async function clearScavengerRecords(context, phoneNumber) {
  const response = await fetch(
    getBaseUrl() + path + 'clearscavengerrecordsbyphone',
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + context.token,
      },
      body: JSON.stringify({
        phoneNumber: phoneNumber,
      }),
    }
  );

  return response.json();
}

export function getAppLogs(context, phoneNumber, count) {
  return axios({
    method: 'post',
    url: getBaseUrl() + path + 'getapplogs',
    headers: { Authorization: `Bearer ${context.token}` },
    data: {
      phoneNumber,
      count,
    },
  });
}
